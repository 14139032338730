import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MainContextComponent from "../components/main-context";
import { LoginView } from "../views";
import AuthorizedRoute from "../components/AuthorizedRoute";
import VerifyEmail from "../views/verify_email";
import RegisterView from "../views/register";
import { Routes as RoutesPath } from "../config/routes";
import DashboardRoutes from "./dashboard.routs";
import Landing from "../views/landing";
import PrePayment from "../views/stripe/landing";
import PaymentResult from "../views/stripe/return";
import SuccessPageView from "../views/stripe/success";
import TermsView from "../views/terms";
import PolicyView from "../views/policy";
import GDPRView from "../views/gdpr";
import FaqView from "../views/faq";
import BusinessSetupSubscriptionView from "../views/businessSetup/subscription";
import LandingRoutes from "./landing.routs";

const AppRoute = () => {
    const RedirectToShop = () => {
        return <Navigate to={"/login"} />;
    };
    return (
        <Suspense>
            <MainContextComponent>
                <Routes>
                    <Route path={"/*"} element={<LandingRoutes />} />
                    {/* <Route path={RoutesPath.landing} element={<Landing />} />
                    <Route
                        path={RoutesPath.PubSubscription}
                        element={<BusinessSetupSubscriptionView />}
                    /> */}
                    <Route path={RoutesPath.login} element={<LoginView />} />
                    <Route path={RoutesPath.register} element={<RegisterView />} />
                    <Route path={RoutesPath.terms} element={<TermsView />} />
                    <Route path={RoutesPath.privacy} element={<PolicyView />} />
                    <Route path={RoutesPath.gdpr} element={<GDPRView />} />
                    <Route path={RoutesPath.faq} element={<FaqView />} />

                    <Route
                        path={RoutesPath.refresh}
                        element={
                            <AuthorizedRoute userRole={""} route={null}>
                                <PrePayment />
                            </AuthorizedRoute>
                        }
                    />

                    <Route
                        path={RoutesPath.success}
                        element={
                            <AuthorizedRoute userRole={""} route={null}>
                                <SuccessPageView />
                            </AuthorizedRoute>
                        }
                    />

                    <Route
                        path={RoutesPath.return}
                        element={
                            <AuthorizedRoute userRole={""} route={null}>
                                <PaymentResult />
                            </AuthorizedRoute>
                        }
                    />

                    <Route
                        path={RoutesPath.verifyEmail}
                        element={
                            <AuthorizedRoute userRole={""} route={null}>
                                <VerifyEmail />
                            </AuthorizedRoute>
                        }
                    />
                    <Route path="/admin/*" element={<DashboardRoutes />} />
                </Routes>
            </MainContextComponent>
        </Suspense>
    );
};

export default AppRoute;
