export const API_ENDPOINTS = {
  PRODUCTS: '/products',
  PRODUCTS_POPULAR: '/popular-products',
  PRODUCTS_REVIEWS: '/reviews',
  PRODUCTS_REVIEWS_ABUSE_REPORT: '/abusive_reports',
  PRODUCTS_QUESTIONS: '/questions',
  FEEDBACK: '/feedbacks',
  CATEGORIES: '/categories',
  TYPES: '/types',
  TAGS: '/tags',
  SHOPS: '/shops',
  AUTHORS: '/authors',
  AUTHORS_TOP: '/top-authors',
  MANUFACTURERS: '/manufacturers',
  MANUFACTURERS_TOP: '/top-manufacturers',
  COUPONS: '/coupons',
  COUPONS_VERIFY: '/coupons/verify',
  ORDERS: '/orders',
  ORDERS_REFUNDS: '/refunds',
  ORDERS_PAYMENT: '/orders/payment',
  ORDERS_CHECKOUT_VERIFY: '/orders/checkout/verify',
  ORDERS_DOWNLOADS: '/downloads',
  GENERATE_DOWNLOADABLE_PRODUCT_LINK: '/downloads/digital_file',
  USERS: '/users',
  USERS_ADDRESS: '/address',
  USERS_ME: '/user/profile_show/',

  USERS_LOGIN: '/user/token-auth/',

  USERS_REGISTER: '/user/register/',

  USERS_FORGOT_PASSWORD: '/forget-password',
  USERS_VERIFY_FORGOT_PASSWORD_TOKEN: '/verify-forget-password-token',
  USERS_RESET_PASSWORD: '/reset-password',
  USERS_CHANGE_PASSWORD: '/change-password',
  USERS_LOGOUT: '/logout',
  USERS_SUBSCRIBE_TO_NEWSLETTER: '/subscribe-to-newsletter',
  USERS_CONTACT_US: '/contact-us',
  USERS_WISHLIST: '/my-wishlists',
  WISHLIST: '/wishlists',
  USERS_WISHLIST_TOGGLE: '/wishlists/toggle',
  SOCIAL_LOGIN: '/social-login-token',
  SEND_OTP_CODE: '/send-otp-code',
  VERIFY_OTP_CODE: '/user/verify_email/',

  OTP_LOGIN: '/otp-login',
  UPDATE_CONTACT: '/update-contact',
  SETTINGS: '/settings',
  UPLOADS: '/attachments',
  MY_QUESTIONS: '/my-questions',
  MY_REPORTS: '/my-reports',
  CARDS: '/cards',
  SET_DEFAULT_CARD: '/set-default-card',
  SAVE_PAYMENT_METHOD: '/save-payment-method',
  PAYMENT_INTENT: '/payment-intent',
  BEST_SELLING_PRODUCTS: '/best-selling-products',
  SEND_VERIFICATION_EMAIL: '/email/verification-notification',
  USERS_UPDATE_EMAIL: '/update-email',
  STORE_NOTICES: 'store-notices',
  NEAR_SHOPS: '/near-by-shop',

  BUSINESS_DETAIL_LIST: '/business/business_details_combo_show/',
  BUSINESS_DETAIL_LIMITED_SHOW: '/business/business_details_grid_show/',
  BUSINESS_DETAIL_BY_ID_SHOW: '/business/business_details_by_id_show/',
  BUSINESS_DETAIL_DETAIL_CREATE: '/business/business_details_create/',
  BUSINESS_DETAIL_DETAIL_UPDATE: '/business/business_details_update/',
  BUSINESS_USER_SHOW: '/business/business_user_show/',
  BUSINESS_DETAIL_USER_CREATE: '/business/business_user_create/',
  BUSINESS_DETAIL_USER_DELETE: '/business/business_user_delete/',
  BUSINESS_BRANCH_LOCATION_FIND_ADDRESS: '/business/business_branch_location_find_address_show/',
  BUSINESS_BRANCH_LOCATION_CREATE: '/business/business_branch_location_create/',
  BUSINESS_BRANCH_LOCATION_LIST: '/business/business_branch_location_show/',
  BUSINESS_BRANCH_LOCATION_DELETE_ITEM: '/business/business_branch_location_delete/',
  BUSINESS_DOCUMENT_CREATE: '/business/documents_of_business_to_GCS_upload/',
  BUSINESS_DOCUMENT_LIST: '/business/document_of_business_show/',
  BUSINESS_DOCUMENT_DELETE: '/business/document_of_business_delete/',
  LOGO_DOCUMENT_CREATE: '/business/logo_image_of_business_to_GCS_upload/',
  LOGO_DOCUMENT_LIST: '/business/logo_image_of_business_show/',
  LOGO_DOCUMENT_DELETE: '/business/logo_image_of_business_delete/',

  BUSINESS_SERVICE_TYPE_FOR_BUSINESS: '/business/service_type_for_business_show/',
  BUSINESS_SECTOR_SHOW: '/business/sectors_show/',
  BUSINESS_JOB_CATEGORIES: '/business/job_as_service_categories_show/',
  BUSINESS_JOB_TITLE: '/business/job_as_service_jobtitle_show/',
  BUSINESS_BUSINESS_SECTOR_CREATE: '/business/business_sector_create/',
  BUSINESS_BUSINESS_SECTOR_SHOW: '/business/business_sector_show/',
  PACKAGE_TYPE_OF_DELIVERY_LIST: '/business/package_type_for_delivery_show/',

  BUSINESS_FINANCE_BANK_CARD_CREATE: '/business_finance/bank_card_create/',
  BUSINESS_FINANCE_BANK_CARD_LIST: '/business_finance/bank_card_show/',
  BUSINESS_FINANCE_BANK_CARD_DELETE: '/business_finance/bank_card_del/',
  BUSINESS_FINANCE_BANK_ACCOUNT_CREATE: '/business_finance/business_connected_account_create/',
  BUSINESS_FINANCE_BANK_ACCOUNT_LIST: '/business_finance/business_connected_account_show/',
  BUSINESS_FINANCE_BANK_ACCOUNT_DELETE: '/business_finance/business_connected_account_del/',
  BUSINESS_FINANCE_CREATE_PAYMENT_INTENT: '/business_finance/create_payment_intent/',
  BUSINESS_FINANCE_CONFIRM_PAYMENT_INTENT: '/business_finance/confirm_payment_intent/',
  BUSINESS_FINANCE_RETRIEVE_PAYMENT_INTENT: '/business_finance/retrieve_payment_intent/',
  BUSINESS_FINANCE_PAYMENT_CONFIG: '/business_finance/config/',


  PRODUCT_MANAGEMENT_POLICY_LIST: '/business/policy_text_show/',
  PRODUCT_MANAGEMENT_POLICY_TYPE_LIST: '/business/policy_type_show/',
  PRODUCT_MANAGEMENT_POLICY_CREATE: '/business/policy_text_to_GCS_upload/',
  PRODUCT_MANAGEMENT_POLICY_DELETE: '/business/policy_text_delete/',

  PRODUCT_MANAGEMENT_POLICY_TEXT_CREATE: '/business/policy_text_create/',
  PRODUCT_MANAGEMENT_POLICY_TEXT_GET_BY_ID: '/business/policy_text_by_ID_show/',

  PRODUCT_MANAGEMENT_INVENTORY_PRODUCT_CONDITION_LIST: '/business/product_condition_show/',

  PRODUCT_MANAGEMENT_MARKET_PLACE_LIST: '/business/marketplace_show/',
  PRODUCT_MANAGEMENT_CATEGORY_LIST: '/business/product_category_by_id_show/',
  PRODUCT_MANAGEMENT_URL_AVAILABILITY: '/business/business_profile_url_availability_check_show/',
  BUSINESS_PROFILE_CREATE_OR_UPDATE: '/business/business_profile_create_or_update/',
  BUSINESS_PROFILE_BY_ID_SHOW: '/business/business_profile_by_id_show/',

  BUSINESS_IN_PERSON_SERVICES_CREATE: '/business/business_in_person_services_create/',
  BUSINESS_IN_PERSON_GRID_SHOW: '/business/business_in_person_services_grid_show/',
  BUSINESS_IN_PERSON_DELETE_ITEM: '/business/business_in_person_services_del/',
  BUSINESS_IN_PERSON_BY_ID_SHOW: '/business/business_in_person_services_by_id_show/',


  PRODUCT_MANAGEMENT_IMAGE_UPLOAD: '/sector_1/Product_image_to_GCS_upload/',
  PRODUCT_MANAGEMENT_IMAGE_LIST: '/sector_1/Product_image_show/',
  PRODUCT_MANAGEMENT_IMAGE_DELETE: '/sector_1/Product_image_delete/',

  PRODUCT_MANAGEMENT_VIDEO_UPLOAD: '/sector_1/Product_video_to_GCS_upload/',
  PRODUCT_MANAGEMENT_VIDEO_LIST: '/sector_1/Product_video_show/',
  PRODUCT_MANAGEMENT_VIDEO_DELETE: '/sector_1/Product_video_delete/',

  PRODUCT_MANAGEMENT_INVENTORY_CREATE_OR_UPDATE: '/sector_1/Product_inventory_create_or_update/',
  PRODUCT_MANAGEMENT_INVENTORY_DETAILS: '/sector_1/Product_inventory_details_by_sku_single_show/',
  PRODUCT_MANAGEMENT_INVENTORY_LIST: '/sector_1/Product_inventory_grid_show/',
  PRODUCT_MANAGEMENT_INVENTORY_DELETE: '/sector_1/Product_inventory_delete/',
  PRODUCT_MANAGEMENT_INVENTORY_BRAND_LIST: '/sector_1/brand_by_name_show/',

  PRODUCT_MANAGEMENT_INVENTORY_GROUP_CREATE_OR_UPDATE: '/sector_1/Product_inventory_group_create_or_update/',
  PRODUCT_MANAGEMENT_INVENTORY_GROUP_DETAILS: '/sector_1/Product_inventory_group_details_by_sku_groupcode_show/',
  PRODUCT_MANAGEMENT_INVENTORY_GROUP_LIST: '/sector_1/Product_inventory_group_grid_show/',
  PRODUCT_MANAGEMENT_INVENTORY_GROUP_DELETE: '/sector_1/Product_inventory_group_delete/',


  PRODUCT_MANAGEMENT_OFFER_GRID_LIST: '/sector_1/Offer_grid_show/',

  PRODUCT_MANAGEMENT_OFFER_CREATE_OR_UPDATE: '/sector_1/Offer_create_or_update/',
  PRODUCT_MANAGEMENT_OFFER_DETAILS: '/sector_1/Offer_details_by_id_or_sku_show/',

  PRODUCT_MANAGEMENT_LISTING_GRID_LIST: '/sector_1/listing_for_seller_grid_show/',
  PRODUCT_LISTING_PUBLISH_BY_OFFER_CREATE_OR_UPDATE: '/sector_1/publish_offer_for_listing_create_or_update/',
  PRODUCT_LISTING_PUBLISH_BY_OFFER_GROUP_CREATE_OR_UPDATE: '/sector_1/publish_ProductInvGroup_listing_create_or_update/',
  PRODUCT_LISTING_UN_LIST: '/sector_1/listing_del/',

  AI_ANALYSIS_DESCRIPTION_GENERATOR: '/analysis/text_gen_view/',
};
