import PhoneBackgroundImageV3 from "../../assets/phone02.png";
import IconDepositLight from "../../assets/icon/deposit-light.png";
import IconDocument from "../../assets/icon/document.png";
import IconCorporateLight from "../../assets/icon/corporate-light.png";

export default function GetStarted() {
    const item = (title: string, subtitle: string, image: any) => {
        return (
            <div className="flex rounded-lg p-2 space-x-8 w-full h-44">
                <img
                    src={image}
                    alt="landing"
                    style={{
                        width: "70px",
                        height: "70px",
                        position: "relative",
                    }}
                />

                <div>
                    <h1 className="text-xl text-gray-900 mb-4">{title}</h1>

                    <span className="text-lg leading-6 text-gray-800">{subtitle}</span>
                </div>
            </div>
        );
    };
    return (
        <div
            style={{
                marginTop: "80px",
                paddingLeft: "200px",
                paddingRight: "200px",
            }}
            className="w-full"
        >
            <div>
                <h1 className="text-3xl text-slate-900 font-bold mb-4">
                    {"Get Started with Milborak"}
                </h1>

                <div className="flex justify-between w-full mt-16 space-y-15">
                    <div className="w-2/3 mt-4">
                        {item(
                            "Step 1: Register Your Account",
                            "Register your account in Milborak business web.",
                            IconCorporateLight
                        )}

                        {item(
                            "Step 2: Complete Business Details",
                            "Build a strong online presence in simple steps by providing essential details about your business. Let the world discover your unique brand.",
                            IconDocument
                        )}

                        {item(
                            "Step 3: Showcase Your Offerings",
                            "Whether you're selling products or providing services, showcase your inventory and offers. Enable customers to find you effortlessly and embark on a seamless shopping experience or interacting with your business.",
                            IconDepositLight
                        )}
                    </div>
                    <div className="w-1/3 mt-4 align-center justify-center">
                        <img
                            src={PhoneBackgroundImageV3}
                            alt="landing"
                            style={{
                                width: "auto",
                                height: "550px",
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
