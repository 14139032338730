import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, ProductManagementPolicyItem } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { EditIcon } from "../icons/edit copy";
import { TrashIcon } from "../icons/trash";
import { useModalAction } from "../../provider/modal.context";
import Button from "../ui/button";
import { Eye } from "../icons/eye-icon";

export interface IPolicySelected extends ProductManagementPolicyItem {}

export type IProps = {
    items: IPolicySelected[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IPolicySelected) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const AddOfferPolicyList = ({
    items,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
}: IProps) => {
    const { t } = useTranslation();

    const { openModal } = useModalAction();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    let columns = [
        {
            title: t("table:table-item-file-name"),
            dataIndex: "file_name",
            key: "file_name",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (name: string) => {
                return <span className="truncate whitespace-nowrap">{name ?? "-"}</span>;
            },
        },
        {
            title: "Type",
            dataIndex: "policy_type",
            key: "policy_type",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 50,
            render: function Render(value: number, record: IPolicySelected) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                        className="space-x-4"
                    >
                        <button
                            name="preview"
                            onClick={() => {
                                window.open(record._url, "_blank");
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={"preview"}
                        >
                            <Eye width={16} />
                        </button>

                        <button
                            form="deleteItemForm"
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={items}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default AddOfferPolicyList;
