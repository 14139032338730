import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import {
    AvailabilityTypeModel,
    ProductManagementInventoryCreateOrUpdateResponse,
    SortOrder,
    BusinessDocumentItem,
    BusinessSetupBusinessProfileCreateOrUpdateReq,
    LicenseOfCertificateListItem,
    BusinessSetupBusinessProfileUrlAvailabilityReq,
    BusinessSetupBusinessProfileUrlAvailabilityResponse,
    BusinessSetupBusinessProfileByIdShowReq,
    BusinessSetupBusinessProfileByIdShowResponse,
    BusinessSetupBusinessProfileByIdShowItem,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useBusinessProfileByIdShow,
    useBusinessProfileCreateOrUpdate,
    useProductManagementProfileUrlAvailability,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import TextArea from "../ui/text-area";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import BusinessServiceImageList from "./business-service-image-list";
import ProfileManagementTeamList from "./profile-management-team-list";
import Image01 from "../../assets/temp/img-01.png";
import Image02 from "../../assets/temp/img-01.png";
import Radio from "../ui/radio/radio";

type FormValues = {
    about: string;
    experience: string;
    history: string;
    webAddress: string;
    templateCode: string;
};

const defaultValues = {
    about: "",
    experience: "",
    history: "",
    webAddress: "",
    templateCode: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateBusinessProfileForm({
    isEditMode,
}: {
    isEditMode?: boolean;
}) {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();
    const { user, businessItem } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [serviceTypeTitle, setServiceTypeTitle] = useState<string>("");
    const [initData, setInitData] = useState<boolean>(false);

    const [selectedMemberList, setSelectedMemberList] = useState<AvailabilityTypeModel[]>([]);
    const [selectedBusinessPartnerList, setSelectedBusinessPartnerList] = useState<
        AvailabilityTypeModel[]
    >([]);
    const [selectedLicenseList, setSelectedLicenseList] = useState<LicenseOfCertificateListItem[]>(
        []
    );

    const [webAddress, setWebAddress] = useState<string>("");
    const [webAddressError, setWebAddressError] = useState<string | undefined>(undefined);

    const [selectedProductDocumentList, setSelectedProductDocumentList] = useState<
        BusinessDocumentItem[]
    >([]);

    const [businessProfileDetails, setBusinessProfileDetails] = useState<
        BusinessSetupBusinessProfileByIdShowItem | undefined
    >();

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);

    const {
        mutate: mutateBusinessProfileByIdShow,
        isLoading: isLoadingBusinessProfileByIdShow,
        serverError: serverErroBusinessProfileByIdShow,
        setServerError: setServerErroBusinessProfileByIdShow,
    } = useBusinessProfileByIdShow();

    const {
        mutate: mutateBusinessProfileCreateOrUpdate,
        isLoading: isLoadinBusinessProfileCreateOrUpdate,
        serverError: serverErroBusinessProfileCreateOrUpdate,
        setServerError: setServerErroBusinessProfileCreateOrUpdate,
    } = useBusinessProfileCreateOrUpdate();

    const {
        mutate: mutateProfileUrlAvailability,
        isLoading: isLoadinProfileUrlAvailability,
        serverError: serverErroProfileUrlAvailability,
        setServerError: setServerErroProfileUrlAvailability,
    } = useProductManagementProfileUrlAvailability();

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        setError,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    useEffect(() => {
        if (webAddress && initData) {
            const timer = setTimeout(() => {
                if (webAddress.length >= 2) {
                    if (webAddress !== businessProfileDetails?.dedicated_web_address)
                        checkProfileUrl();
                }
            }, 500);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [webAddress, initData]);

    useEffect(() => {
        if (businessItem) getData();
    }, [businessItem]);

    const getData = async () => {
        await getBusinessProfileByIdShowItem();
    };

    const checkProfileUrl = async () => {
        const input: BusinessSetupBusinessProfileUrlAvailabilityReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            web_url: webAddress,
        };

        await mutateProfileUrlAvailability(input, {
            onSuccess: (successData: BusinessSetupBusinessProfileUrlAvailabilityResponse) => {
                console.log("successData => ", successData);
                setWebAddressError(undefined);
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                setWebAddressError(data?.general_result);
                // toast.error(data?.general_result);
            },
        });
    };

    const getBusinessProfileByIdShowItem = async (skuCode?: string) => {
        const input: BusinessSetupBusinessProfileByIdShowReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
        };

        await mutateBusinessProfileByIdShow(input, {
            onSuccess: (successData: BusinessSetupBusinessProfileByIdShowResponse) => {
                if (successData.final_result_code === "000") {
                    setBusinessProfileDetails(successData.captured_data);
                    // setInitData(true);
                    formReset(successData.captured_data);
                }
            },
        });
    };

    const formReset = (item?: BusinessSetupBusinessProfileByIdShowItem) => {
        if (item) {
            //         about: string;
            // experience: string;
            // history: string;
            // webAddress: string;
            reset({
                about: item.about_business,
                experience: item.experience_of_business,
                history: item.business_history,
                templateCode: item.web_template_code,
            });

            setWebAddress(item.dedicated_web_address);
            setServiceTypeTitle(item.business_type);

            setSelectedProductDocumentList(
                item.story_sliders_urls.map((mapItem) => {
                    return {
                        file_id: 0,
                        file_name: mapItem.substring(mapItem.lastIndexOf("/") + 1),
                        file_urls: [
                            {
                                url: mapItem,
                                title: "",
                            },
                        ],
                        url_type: "",
                    };
                })
            );

            setSelectedMemberList(
                item.management_team?.map((mapItem, index) => {
                    const item: AvailabilityTypeModel = {
                        id: index,
                        title: mapItem.full_name,
                        value: mapItem.role,
                    };

                    return item;
                }) ?? []
            );

            setSelectedBusinessPartnerList(
                item.partners?.map((mapItem, index) => {
                    const item: AvailabilityTypeModel = {
                        id: index,
                        title: mapItem.partner_name,
                        value: mapItem.details,
                    };

                    return item;
                }) ?? []
            );

            setSelectedLicenseList(
                item.licence_or_certificate?.map((mapItem, index) => {
                    const item: LicenseOfCertificateListItem = {
                        documentItem: {
                            file_id: 0,
                            file_name: mapItem.url.substring(mapItem.url.lastIndexOf("/") + 1),
                            file_urls: [
                                {
                                    url: mapItem.url,
                                    title: "",
                                },
                            ],
                            url_type: "",
                        },
                        licence_name: mapItem.licence_name,
                        date_of_achievement: mapItem.date_of_achievement,
                        details: mapItem.details,
                        url: mapItem.url,
                    };

                    return item;
                }) ?? []
            );
        } else {
            // reset(defaultValues);
            // setPolicyTypeItemSelected(undefined);
        }
    };

    const onSubmit = async (values: FormValues) => {
        try {
            console.log("values => ", values);

            let businessProfile: BusinessSetupBusinessProfileCreateOrUpdateReq = {
                email: user?.email ?? "",
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
                dedicated_web_address: webAddress,
                story_sliders_urls: selectedProductDocumentList.map(
                    (mapItem) => mapItem.file_urls[0].url
                ),
                about_business: values.about,
                experience_of_business: values.experience,
                business_history: values.history,
                web_template_code: values.templateCode,
                management_team: selectedMemberList.map((mapItem) => {
                    return {
                        full_name: mapItem.title,
                        role: mapItem.value,
                    };
                }),
                partners: selectedBusinessPartnerList.map((mapItem) => {
                    return {
                        partner_name: mapItem.title,
                        details: mapItem.value,
                    };
                }),
                licence_or_certificate: selectedLicenseList.map((mapItem) => {
                    return {
                        licence_name: mapItem.licence_name,
                        date_of_achievement: mapItem.date_of_achievement,
                        details: mapItem.details,
                        url: mapItem.documentItem.file_urls[0].url,
                    };
                }),
            };

            console.log("businessProfile => ", businessProfile);

            await mutateBusinessProfileCreateOrUpdate(businessProfile, {
                onSuccess: (successData: ProductManagementInventoryCreateOrUpdateResponse) => {
                    if (successData.final_result_code === "000") {
                        toast.success(successData.general_result);
                        // navigate(-1);
                    } else {
                        toast.error(successData.general_result);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            });
        } catch (error) {
            console.log("error => ", error);
        }
    };

    return (
        <>
            <form id={"business_profile_form"} onSubmit={handleSubmit(onSubmit)} className="">
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Enter product details information "}
                        details={[
                            "Filling this page is optional and it just will help you to introduce your company to your (potential) customers, so we suggest to fulfil the items accurately.",
                            "The responsibility of inaccurate data will be on your company.",
                            "These items will be shown on your standalone webpage and your (potential) customers will see them.",
                        ]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <Label>
                            {
                                "Business service image: (Some images that will be displayed on your webpage about you)"
                            }
                        </Label>

                        <BusinessServiceImageList
                            imageList={selectedProductDocumentList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: BusinessDocumentItem) => {
                                setSelectedProductDocumentList(
                                    selectedProductDocumentList.filter((filterItem) => {
                                        return filterItem.file_id !== item.file_id;
                                    })
                                );
                            }}
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addAspectDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Document_From_DocumentList",
                                        {
                                            item: { selectedItems: selectedProductDocumentList },
                                            isMultiSelect: true,

                                            callback: (
                                                selectedImageList: BusinessDocumentItem[]
                                            ) => {
                                                setSelectedProductDocumentList(selectedImageList);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new"}
                            </Button>
                        </div>

                        <TextArea
                            label={"About business"}
                            {...register("about")}
                            error={t(errors.about?.message!)}
                            variant="outline"
                            placeholder="About your business"
                            className="mb-5"
                            maxLength={1900}
                        />

                        <TextArea
                            label={"Experience"}
                            {...register("experience")}
                            error={t(errors.experience?.message!)}
                            variant="outline"
                            placeholder="Business experience"
                            className="mb-5"
                            maxLength={1900}
                            note="Some experience of your business (optional)"
                        />

                        <TextArea
                            label={"History"}
                            {...register("history")}
                            error={t(errors.history?.message!)}
                            variant="outline"
                            placeholder="Business history"
                            className="mb-5"
                            maxLength={1900}
                            note="Business history (optional)"
                        />

                        <Label>{"Management team"}</Label>

                        <ProfileManagementTeamList
                            imageList={selectedMemberList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: AvailabilityTypeModel) => {
                                setSelectedMemberList(
                                    selectedMemberList.filter((filterItem) => {
                                        return filterItem.id !== item.id;
                                    })
                                );
                            }}
                            onPreviewItem={(item: AvailabilityTypeModel) => {
                                openModal(
                                    "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                    {
                                        item: {},
                                        isPreview: true,
                                        valueTitle: item.title,
                                        valueValue: item.value,
                                        inputTextArea: true,
                                        modalTitle: "Add new member",
                                        maxLengthValue: 100,
                                        labelTitle: "Full name",
                                        labelValue: "Role",
                                        placeholderTitle: "Full name",
                                        placeholderValue: "Role",
                                        callback: (item: AvailabilityTypeModel) => {
                                            setSelectedMemberList([
                                                ...selectedMemberList,
                                                {
                                                    ...item,
                                                    id: new Date().getTime(),
                                                },
                                            ]);
                                        },
                                    }
                                );
                            }}
                            options={{
                                title1: "Full name",
                                title2: "Role",
                            }}
                            isShowPreview
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addAspectDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                        {
                                            item: {},
                                            inputTextArea: true,
                                            modalTitle: "Add new member",
                                            // maxLengthTitle: 30,
                                            maxLengthValue: 100,
                                            labelTitle: "Full name",
                                            labelValue: "Role",
                                            placeholderTitle: "Full name",
                                            placeholderValue: "Role",
                                            callback: (item: AvailabilityTypeModel) => {
                                                setSelectedMemberList([
                                                    ...selectedMemberList,
                                                    {
                                                        ...item,
                                                        id: new Date().getTime(),
                                                    },
                                                ]);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new"}
                            </Button>
                        </div>

                        <Label>{"Your business partners (Optional)"}</Label>

                        <ProfileManagementTeamList
                            imageList={selectedBusinessPartnerList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: AvailabilityTypeModel) => {
                                setSelectedBusinessPartnerList(
                                    selectedBusinessPartnerList.filter((filterItem) => {
                                        return filterItem.id !== item.id;
                                    })
                                );
                            }}
                            onPreviewItem={(item: AvailabilityTypeModel) => {
                                openModal(
                                    "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                    {
                                        item: {},
                                        isPreview: true,
                                        valueTitle: item.title,
                                        valueValue: item.value,
                                        inputTextArea: true,
                                        modalTitle: "New partner",
                                        maxLengthTitle: 200,
                                        maxLengthValue: 250,
                                        labelTitle: "Partner name",
                                        placeholderTitle: "Partner name",
                                        labelValue: "Details",
                                        placeholderValue: "Details",
                                        callback: (item: AvailabilityTypeModel) => {
                                            setSelectedBusinessPartnerList([
                                                ...selectedBusinessPartnerList,
                                                {
                                                    ...item,
                                                    id: new Date().getTime(),
                                                },
                                            ]);
                                        },
                                    }
                                );
                            }}
                            options={{
                                title1: "Partner name",
                                title2: "details",
                            }}
                            isShowPreview
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addAspectDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                        {
                                            item: {},
                                            inputTextArea: true,
                                            modalTitle: "New partner",
                                            maxLengthTitle: 200,
                                            maxLengthValue: 250,
                                            labelTitle: "Partner name",
                                            placeholderTitle: "Partner name",
                                            labelValue: "Details",
                                            placeholderValue: "Details",
                                            callback: (item: AvailabilityTypeModel) => {
                                                setSelectedBusinessPartnerList([
                                                    ...selectedBusinessPartnerList,
                                                    {
                                                        ...item,
                                                        id: new Date().getTime(),
                                                    },
                                                ]);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new business partners"}
                            </Button>
                        </div>

                        <Label>{"License or certificate (Optional)"}</Label>

                        <BusinessServiceImageList
                            imageList={selectedLicenseList.map((mapItem) => {
                                return mapItem.documentItem;
                            })}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: BusinessDocumentItem) => {
                                setSelectedLicenseList(
                                    selectedLicenseList.filter((filterItem) => {
                                        return filterItem.documentItem.file_id !== item.file_id;
                                    })
                                );
                            }}
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addAspectDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Document_From_DocumentList",
                                        {
                                            item: { selectedItems: selectedLicenseList },
                                            isEnterDetails: true,
                                            // callback: (selectedImageList: {documentItem: BusinessDocumentItem;}) => {
                                            callback: (
                                                selectedDocumentList: LicenseOfCertificateListItem
                                            ) => {
                                                console.log(
                                                    "selectedDocumentList => ",
                                                    selectedDocumentList
                                                );

                                                setSelectedLicenseList([
                                                    ...selectedLicenseList,
                                                    selectedDocumentList,
                                                ]);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new license or certificate"}
                            </Button>
                        </div>

                        <Label>
                            {
                                "This url will be your web address and it should be based on your company name."
                            }
                        </Label>

                        <Input
                            label={"Your web address"}
                            // {...register("webAddress")}
                            name="dedicateWebAddress"
                            value={webAddress}
                            onChange={(e) => {
                                setWebAddress(e.target.value);
                            }}
                            error={webAddressError}
                            placeholder="Web address url"
                            variant="outline"
                            maxLength={50}
                            onFocus={() => {
                                setInitData(true);
                            }}
                            onBlur={() => {
                                setInitData(false);
                            }}
                            note={"Ex: https://www.milborak.co.uk/business/your_web_addres"}
                        />

                        <div className="flex items-center space-x-2">
                            <Label className="mt-2.5">{"Service type: "}</Label>
                            <h5 className="text-base font-bold text-body-dark">
                                {serviceTypeTitle}
                            </h5>
                        </div>

                        <Label className="">
                            {
                                "Please choose the format for displaying your web page layout from the following options:"
                            }
                        </Label>

                        <div className="flex space-x-4 pt-4">
                            <div className="items-center ">
                                <img
                                    src={Image01}
                                    alt="Template 90001"
                                    style={{
                                        width: "140px",
                                        height: "200px",
                                    }}
                                />

                                <Radio
                                    label={"Template 90001"}
                                    {...register("templateCode")}
                                    id="opt1"
                                    value={"900001"}
                                    className="mt-4"
                                />
                            </div>

                            <div className="items-center ">
                                <img
                                    src={Image02}
                                    alt="Template 90002"
                                    style={{
                                        width: "140px",
                                        height: "200px",
                                    }}
                                />

                                <Radio
                                    label={"Template 90002"}
                                    {...register("templateCode")}
                                    id="opt2"
                                    value={"900002"}
                                    className="mt-4"
                                />
                            </div>
                        </div>
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col ">
                        <div className="mb-4 text-center space-x-4 mt-4">
                            <Button
                                variant={"cancelForm"}
                                form={"backToList"}
                                loading={false}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                {t("form:button-label-cancel")}
                            </Button>

                            {(!params.skuCode || isEditMode) && (
                                <Button
                                    form={"business_profile_form"}
                                    loading={isLoadinBusinessProfileCreateOrUpdate}
                                    disabled={isLoadinBusinessProfileCreateOrUpdate}
                                >
                                    {params.skuCode ? "Update" : t("form:button-label-submit")}
                                </Button>
                            )}
                        </div>
                    </Card>
                </div>
            </form>
        </>
    );
}
