import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enCommon from './locales/en/common.json';
import enForm from './locales/en/form.json';
import enTable from './locales/en/table.json';
import enWidgets from './locales/en/widgets.json';
import enGdpr from './locales/en/gdpr.json'
import enPolicy from './locales/en/policy.json'
import enTerms from './locales/en/terms.json'
import enFaq from './locales/en/faq.json'

i18n.use(initReactI18next).init({
    resources: {
        en: {
            common: { ...enCommon },
            form: { ...enForm },
            table: { ...enTable },
            widgets: { ...enWidgets },
            policy: { ...enPolicy },
            terms: { ...enTerms },
            gdpr: { ...enGdpr },
            faq: { ...enFaq },
        }
    }, // Where we're gonna put translations' files
    lng: "en",     // Set the initial language of the App
});