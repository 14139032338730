import React, { Suspense } from "react";
import { useModalAction, useModalState } from "../../provider/modal.context";
import Modal from "./modal";
import BranchLocationBranchDeleteView from "../businessSetup/branch-location-delete-view";
// import LoginView from "../auth/login-form";

const Login = React.lazy(() => import("../auth/login-form"));
const TeamMemberDeleteUser = React.lazy(() => import("../businessSetup/team-member-delete-view"));
const BranchLocationDeleteBranch = React.lazy(
    () => import("../businessSetup/branch-location-delete-view")
);
const BankAccountDeleteAccount = React.lazy(
    () => import("../businessSetup/bank-account-delete-view")
);
const BusinessDocumentDeleteDocument = React.lazy(
    () => import("../businessSetup/Business-document-delete-view")
);
const ProductManagementDeletePolicy = React.lazy(
    () => import("../productManagement/policy-delete-view")
);
const ProductManagementAddPolicy = React.lazy(() => import("../productManagement/policy-add-view"));
const ProductManagementAddImage = React.lazy(() => import("../productManagement/add-image-view"));
const ProductManagementDeleteImage = React.lazy(
    () => import("../productManagement/image-delete-view")
);
const ProductManagementAddVideo = React.lazy(() => import("../productManagement/video-add-view"));
const ProductManagementDeleteVideo = React.lazy(
    () => import("../productManagement/video-delete-view")
);
const ProductManagementInventorySelectImage = React.lazy(
    () => import("../productManagement/inventory-select-image-view")
);
const ProductManagementInventorySelectVideo = React.lazy(
    () => import("../productManagement/inventory-select-video-view")
);

const ProductManagementInventorySelectAspect = React.lazy(
    () => import("../productManagement/inventory-select-aspect-view")
);

const ProductManagementInventoryImageOrVideoPreview = React.lazy(
    () => import("../productManagement/inventory-select-image-or-video-preview-view")
);

const ProductManagementInventoryDeleteItem = React.lazy(
    () => import("../productManagement/inventory-delete-view")
);

const ProductManagementOfferSelectSKU = React.lazy(
    () => import("../productManagement/offer-select-sku-view")
);

const ProductManagementOfferSelectPolicy = React.lazy(
    () => import("../productManagement/offer-select-policy-view")
);

const ListingUnListConfirmView = React.lazy(
    () => import("../productListing/listing-unlist-confirm-view")
);

const ProductManagementInventorySelectDocument = React.lazy(
    () => import("../businessSetup/inventory-select-document-view")
);

const BusinessInPersonServicesDetailsModal = React.lazy(
    () => import("../InPersonServices/InPersonServicesDetailsModal")
);

const ManagedModal = () => {
    const { isOpen1, isOpen2, view, view2 } = useModalState();
    const { closeModal, closeModal2 } = useModalAction();

    return (
        <>
            <Modal open={isOpen1} onClose={closeModal} isOuterClickClose={isOpen2}>
                {view === "LOGIN_VIEW" && <Login />}
                {view === "TeamMember_Delete_User" && <TeamMemberDeleteUser />}
                {view === "BranchLocation_Delete_Branch" && <BranchLocationDeleteBranch />}
                {view === "BankAccount_Delete_account" && <BankAccountDeleteAccount />}
                {view === "BusinessDocument_Delete_Document" && <BusinessDocumentDeleteDocument />}
                {view === "ProductManagement_Delete_Policy" && <ProductManagementDeletePolicy />}
                {view === "ProductManagement_Add_Policy" && <ProductManagementAddPolicy />}
                {view === "ProductManagement_Add_Image" && <ProductManagementAddImage />}
                {view === "ProductManagement_Delete_Image" && <ProductManagementDeleteImage />}
                {view === "ProductManagement_Add_Video" && <ProductManagementAddVideo />}
                {view === "ProductManagement_Delete_Video" && <ProductManagementDeleteVideo />}
                {view === "ProductManagement_Inventory_Add_Image_From_ImageList" && (
                    <ProductManagementInventorySelectImage />
                )}
                {view === "ProductManagement_Inventory_Add_Video_From_VideoList" && (
                    <ProductManagementInventorySelectVideo />
                )}
                {view === "ProductManagement_Inventory_Add_Aspect_From_AspectList" && (
                    <ProductManagementInventorySelectAspect />
                )}
                {view === "ProductManagement_Image_Or_Video_Preview" && (
                    <ProductManagementInventoryImageOrVideoPreview />
                )}
                {view === "ProductManagement_Inventory_Delete" && (
                    <ProductManagementInventoryDeleteItem />
                )}
                {view === "ProductManagement_Offer_Add_SKU_From_SKUList" && (
                    <ProductManagementOfferSelectSKU />
                )}
                {view === "ProductManagement_Offer_Add_Policy_From_PolicyList" && (
                    <ProductManagementOfferSelectPolicy />
                )}
                {view === "Listing_Un_List_Confirm" && <ListingUnListConfirmView />}
                {view === "ProductManagement_Inventory_Add_Document_From_DocumentList" && (
                    <ProductManagementInventorySelectDocument />
                )}
                {view === "InPersonServicesDetailsModal" && (
                    <BusinessInPersonServicesDetailsModal />
                )}
            </Modal>
            <Modal open={isOpen2} onClose={closeModal2}>
                {view2 === "ProductManagement_Image_Or_Video_Preview" && (
                    <ProductManagementInventoryImageOrVideoPreview isSecond={true} />
                )}
                {view2 === "ProductManagement_Add_Policy" && (
                    <ProductManagementAddPolicy isSecond={true} />
                )}
            </Modal>
        </>
    );
};

export default ManagedModal;
