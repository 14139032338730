export const FountainPenIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.201"
    height="20.2"
    viewBox="0 0 20.201 20.2"
    {...props}
  >
    <path
      data-name="001-pen-tool"
      d="M19.929 8.063 12.138.271a.586.586 0 0 0-.829 0L8.195 3.385a.586.586 0 0 0 0 .829l.468.468-6.485 3.3a.586.586 0 0 0-.313.43L.108 19.421a.586.586 0 0 0 .579.679.592.592 0 0 0 .092-.007l11.007-1.757a.586.586 0 0 0 .43-.313l3.3-6.485.468.468a.586.586 0 0 0 .829 0l3.113-3.113a.586.586 0 0 0 0-.829Zm-5.281 2.6-3.341 6.558-8.928 1.429 6.672-6.672a.994.994 0 0 0 .117.007.954.954 0 1 0-.945-.837L1.55 17.821l1.426-8.927 6.558-3.342 1.229 1.229Zm-5.326.21Zm7.08-.114-4.479-4.476-1.861-1.861-.624-.622 2.285-2.285 6.963 6.963Zm0 0"
      fill="currentColor"
      stroke="#fff"
      strokeWidth=".2"
    />
  </svg>
);
