import Select from "./select/select";
import { Controller } from "react-hook-form";
import { GetOptionLabel } from "react-select";

interface SelectInputProps {
    control: any;
    rules?: any;
    name: string;
    options: object[];
    getOptionLabel?: GetOptionLabel<unknown>;
    getOptionValue?: GetOptionLabel<unknown>;
    isMulti?: boolean;
    isClearable?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    [key: string]: unknown;
    placeholder?: string;
    value?: any;
    onChange?: (value: any) => void;
}

const SelectInput = ({
    control,
    options,
    name,
    rules,
    getOptionLabel,
    getOptionValue,
    disabled,
    isMulti,
    isClearable,
    isLoading,
    placeholder,
    value,
    onChange,
    ...rest
}: SelectInputProps) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            {...rest}
            render={({ field }) => (
                <Select
                    {...field}
                    onChange={(e) => {
                        field.onChange(e);
                        onChange && onChange(e);
                    }}
                    value={value}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    isClearable={isClearable}
                    isLoading={isLoading}
                    options={options}
                    isDisabled={disabled as boolean}
                />
            )}
        />
    );
};

export default SelectInput;
