import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import {
    MappedPaginatorInfo,
    SortOrder,
    ProductManagementImage,
    PickUpAtLocationListItemModel,
} from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { TrashIcon } from "../icons/trash";

export interface IPickupAtLocationItem extends PickUpAtLocationListItemModel {}

export type IProps = {
    items: IPickupAtLocationItem[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IPickupAtLocationItem) => void;
    emptyNode?: any;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const AddInventoryPickupAtLocationList = ({
    items,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
    emptyNode,
}: IProps) => {
    const { t } = useTranslation();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    let columns = [
        {
            title: "Branch",
            dataIndex: "branch",
            key: "branch",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: any) => {
                return (
                    <span className="truncate whitespace-nowrap">
                        {value?.location_name ?? "-"}
                    </span>
                );
            },
        },
        {
            title: "Availibility",
            dataIndex: "availabilityType",
            key: "availabilityType",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: any) => {
                return <span className="truncate whitespace-nowrap">{value?.title ?? "-"}</span>;
            },
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: any) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "FulfillmentValue",
            dataIndex: "fulfillmentValue",
            key: "fulfillmentValue",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: any) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Unit",
            dataIndex: "unit",
            key: "unit",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: any) => {
                return <span className="truncate whitespace-nowrap">{value?.title ?? "-"}</span>;
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 130,
            render: function Render(value: number, record: IPickupAtLocationItem) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            id={"btnDelete"}
                            form={"idDelete"}
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                        {/* <div
                            style={{
                                width: "20px",
                            }}
                        />
                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button> */}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={emptyNode ? emptyNode : t("table:empty-table-data")}
                    data={items}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default AddInventoryPickupAtLocationList;
