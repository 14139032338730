import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import {
    SortOrder,
    ProductManagementInventoryGroupListReq,
    ProductManagementInventoryGroupListResponse,
    ProductManagementInventoryGroupItem,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import { useProductManagementInventoryGroupList } from "../../service/hook/sector_1";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";
import InventoryGroupList from "./inventoryGroup-list";

type FormValues = {
    sku: string;
    brand: any;
    condition: any;
};

const defaultValues = {
    sku: "",
    // policyType: "",
    // policyData: {},
};

export default function CreateOrUpdateProductInventoryGroupForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { businessItem } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [inventoryGroupList, setInventoryGroupList] = useState<
        ProductManagementInventoryGroupItem[]
    >([]);

    const [skuGroupInput, setSkuGroupInput] = useState<string>("");

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const {
        mutate: mutateProductManagementInventoryGroup,
        isLoading: isLoadingProductMangementInventoryGroup,
        serverError: serverErrorProductMangementInventoryGroup,
        setServerError: setServerErrorProductMangementInventoryGroup,
    } = useProductManagementInventoryGroupList();

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    useEffect(() => {
        getProductInventoryGroupList();
    }, [businessItem]);

    const getProductInventoryGroupList = () => {
        const input: ProductManagementInventoryGroupListReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            page: 1,
            size: 100,
        };

        if (skuGroupInput.length > 0) {
            input.sku_group_code = skuGroupInput;
        }

        mutateProductManagementInventoryGroup(input, {
            onSuccess: (successData: ProductManagementInventoryGroupListResponse) => {
                if (successData.final_result_code === "000") {
                    setInventoryGroupList(successData.captured_data);
                }
            },
        });
    };

    const deleteItem = async (item: any) => {
        openModal("ProductManagement_Inventory_Delete", {
            item: { ...item },
            inventoryGroup: true,
            callback: () => {
                getProductInventoryGroupList();
            },
        });
    };

    const onSubmit = async () => {
        getProductInventoryGroupList();
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    details={[
                        t("form:form-description-product-management-inventory-group-01"),
                        t("form:form-description-product-management-inventory-group-02"),
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                    <Input
                        label={"Product code (SKU)"}
                        name="sku"
                        placeholder="SKU code"
                        value={skuGroupInput}
                        onChange={(e) => {
                            setSkuGroupInput(e.target.value);
                        }}
                        error={t(errors.sku?.message!)}
                        variant="outline"
                    />

                    <div
                        className="mb-4 text-start"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className="mb-4 text-start space-x-4"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={false}
                                disabled={false}
                            >
                                {"Search"}
                            </Button>

                            <Button
                                variant={"secondary"}
                                onClick={() => {
                                    navigate(Routes.ProductManagementInventoryGroupAddOrDetails);
                                }}
                                className={"ml-4"}
                                loading={isLoadingProductMangementInventoryGroup}
                                disabled={isLoadingProductMangementInventoryGroup}
                            >
                                {"Add new product group"}
                            </Button>
                        </div>
                    </div>

                    <InventoryGroupList
                        inventoryList={inventoryGroupList}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onDeleteItem={(item: ProductManagementInventoryGroupItem) => {
                            deleteItem(item);
                        }}
                    />
                </Card>
            </div>
        </>
    );
}
