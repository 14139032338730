import React from "react";
import "./assets/css/main.css";
import "react-toastify/dist/ReactToastify.css";
import { ModalProvider } from "./provider/modal.context";
import AppRoute from "./routes/app.routs";
import ManagedModal from "./components/modal/managed-modal";
import QueryProvider from "./service/client/query-provider";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App() {
    return (
        <QueryProvider>
            <BrowserRouter>
                <ModalProvider>
                    <AppRoute />
                    <ManagedModal />
                    <ToastContainer />
                </ModalProvider>
            </BrowserRouter>
        </QueryProvider>
    );
}

export default App;
