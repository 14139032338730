import React from "react";
import { useTranslation } from "react-i18next";
// import SubscriptionForm from "../../../components/businessSetup/subscription-form";
import SubscriptionForm2 from "../../../components/businessSetup/subscription-form2";

export default function BusinessSetupSubscriptionView({ children, layout }: any) {
    const { t } = useTranslation();

    return (
        <>
            {/* <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
                <h1 className="text-lg font-semibold text-heading">
                    {`${t("common:sidebar-nav-item-setup-business")} / ${t(
                        "common:sidebar-nav-sub-item-subscription"
                    )}`}
                </h1>
            </div> */}
            <SubscriptionForm2 />
        </>
    );
}
