import { useCallback, useEffect, useState } from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    BusinessDetailByIdItem,
    ProductManagementBrandListReq,
    ProductManagementInventoryBrandItem,
    ProductManagementInventoryBranchListResponse,
    ProductManagementInventoryItem,
    ProductManagementInventoryListResponse,
    ProductManagementInventoryProductConditionItem,
    ProductManagementInventoryProductConditionListResponse,
    ProductManagementListReq,
    ProductManagementPolicyTypeItem,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import { useProductManagementProductConditionList } from "../../service/hook/business";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import {
    useProductManagementBrandList,
    useProductManagementList,
} from "../../service/hook/sector_1";
import InventoryList from "./inventory-list";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";

function BrandList({
    control,
    errors,
    items,
    isLoading,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementInventoryBrandItem[];
    isLoading: boolean;
    defaultValue?: ProductManagementInventoryBrandItem;
    onChangeValue: (item: ProductManagementInventoryBrandItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.brand?.message as string;

    return (
        <div className="mb-5 w-3/5">
            <Label>{t("form:select-label-brand")}</Label>
            <SelectInput
                name="brand"
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementInventoryBrandItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => `${option.brand_name} - ${option.category}`}
                getOptionValue={(option: any) => option.brand_name}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

function ProductCondition({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementInventoryProductConditionItem[];
    defaultValue?: ProductManagementInventoryProductConditionItem;
    onChangeValue: (item: ProductManagementInventoryProductConditionItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.condition?.message as string;

    return (
        <div className="mb-5 w-full">
            <Label>{t("form:select-label-product-condition")}</Label>
            <SelectInput
                name="productCondition"
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementInventoryProductConditionItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.condition_title}
                getOptionValue={(option: any) => option.condition_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

type FormValues = {
    sku: string;
    brand: any;
    condition: any;
};

const defaultValues = {
    sku: "",
    // policyType: "",
    // policyData: {},
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateProductInventoryForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [brandList, setBrandList] = useState<ProductManagementInventoryBrandItem[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<
        ProductManagementInventoryBrandItem | undefined
    >(undefined);

    const [productConditionList, setProductConditionList] = useState<
        ProductManagementInventoryProductConditionItem[]
    >([]);
    const [selectedProductCondition, setSelectedProductCondition] = useState<
        ProductManagementInventoryProductConditionItem | undefined
    >(undefined);

    const [inventoryList, setInventoryList] = useState<ProductManagementInventoryItem[]>([]);
    const [policyTypeItemSelected, setPolicyTypeItemSelected] = useState<
        ProductManagementPolicyTypeItem | undefined
    >(undefined);

    const [addOrEditMode, setAddOrEditMode] = useState<boolean>(true);
    const [productPolicyList, setProductPolicyList] = useState<any[]>();
    const [document, setDocument] = useState<any>();

    const [editItem, setEditItem] = useState<BusinessDetailByIdItem | null>();
    const [brandName, setBrandName] = useState<string>("");
    const [skuInput, setSkuInput] = useState<string>("");

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const {
        mutate: mutateProductManagementInventory,
        isLoading: isLoadingProductMangementInventory,
        serverError: serverErrorProductMangementInventory,
        setServerError: setServerErrorProductMangementInventory,
    } = useProductManagementList();

    const {
        mutate: mutateProductManagementInventoryBrand,
        isLoading: isLoadingProductMangementInventoryBrand,
        serverError: serverErrorProductMangementInventoryBrand,
        setServerError: setServerErrorProductMangementInventoryBrand,
    } = useProductManagementBrandList();

    const {
        mutate: mutateProductManagementProductCondition,
        isLoading: isLoadingProductMangementProductCondition,
        serverError: serverErrorProductMangementProductCondition,
        setServerError: setServerErrorProductMangementProductCondition,
    } = useProductManagementProductConditionList();

    function handlePagination(current: any) {
        setPage(current);
    }

    // const resolver = useYupValidationResolver(productPolicyValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    useEffect(() => {
        getProductInventoryBrandList();
        getProductInventoryProductConditionList();
        getProductInventoryProductList();
    }, [businessItem]);

    useEffect(() => {
        if (brandName) {
            const timer = setTimeout(() => {
                if (brandName.length > 1) getProductInventoryBrandList(brandName);
                if (brandName.length === 0) {
                    getProductInventoryBrandList("", true);
                }
            }, 1000);
            return () => clearTimeout(timer);
        } else if (brandName.length === 0) {
            getProductInventoryBrandList("", true);
        }
    }, [brandName]);

    const getProductInventoryBrandList = (brand?: string, clear?: boolean) => {
        const brandReq: ProductManagementBrandListReq = {
            brand_name: clear ? "" : brand ? brand : brandName,
        };

        mutateProductManagementInventoryBrand(brandReq, {
            onSuccess: (successData: ProductManagementInventoryBranchListResponse) => {
                if (successData.final_result_code === "000") {
                    setBrandList(successData.captured_data);
                }
            },
        });
    };

    const getProductInventoryProductConditionList = (brand?: string, clear?: boolean) => {
        mutateProductManagementProductCondition(undefined, {
            onSuccess: (successData: ProductManagementInventoryProductConditionListResponse) => {
                if (successData.final_result_code === "000") {
                    setProductConditionList(successData.captured_data);
                }
            },
        });
    };

    const getProductInventoryProductList = () => {
        const input: ProductManagementListReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            page: 1,
            size: 100,
        };

        if (skuInput.length > 0) {
            input.sku_code = skuInput;
        }

        if (selectedProductCondition) {
            input.product_condition_id = selectedProductCondition.condition_id;
            input.product_condition_description = selectedProductCondition.condition_title;
        }

        if (selectedBrand) {
            input.brand_id = selectedBrand.brand_id;
            input.brand = selectedBrand.brand_name;
        }

        mutateProductManagementInventory(input, {
            onSuccess: (successData: ProductManagementInventoryListResponse) => {
                if (successData.final_result_code === "000") {
                    setInventoryList(successData.captured_data);
                }
            },
        });
    };

    const formReset = (item?: BusinessDetailByIdItem) => {
        if (item) {
            reset({
                // isReceiveUpdate: item.accepted_to_get_updates_via_sms_email,
                // businessName: item.business_name,
                // type: item.business_type,
                // vatNumber: item.vat_registration_number_tax,
            });
            // getCountryItem(item.country ?? "", (country: CountryItem) => {
            //     setCountryItem(country);
            //     setValue("country", country);
            // });
        } else {
            reset(defaultValues);
            setPolicyTypeItemSelected(undefined);
        }
    };

    const deleteItem = async (item: any) => {
        openModal("ProductManagement_Inventory_Delete", {
            item: { ...item },
            callback: () => {
                // getProductImageList(fileName ?? "");
            },
        });
    };

    const onSubmit = async () => {
        getProductInventoryProductList();
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    details={[
                        t("form:form-description-product-management-image-01"),
                        t("form:form-description-product-management-image-02"),
                        t("form:form-description-product-management-image-03"),
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                    <Input
                        label={"Product code (SKU)"}
                        name="sku"
                        placeholder="SKU code"
                        value={skuInput}
                        onChange={(e) => {
                            setSkuInput(e.target.value);
                        }}
                        error={t(errors.sku?.message!)}
                        variant="outline"
                    />
                    <div
                        className="w-full space-x-4"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Input
                            label={t("form:input-label-brand-search-key")}
                            name="brandSearchKey"
                            placeholder="Enter text for filter brand list"
                            value={brandName}
                            onChange={(e) => {
                                setBrandName(e.target.value);
                            }}
                            // error={t(errors.policyName?.message!)}
                            variant="outline"
                            className="w-2/5"
                        />

                        <BrandList
                            control={control}
                            errors={errors}
                            items={brandList}
                            isLoading={isLoadingProductMangementInventoryBrand}
                            defaultValue={selectedBrand}
                            onChangeValue={(item) => {
                                setSelectedBrand(item);
                            }}
                        />
                    </div>

                    <ProductCondition
                        control={control}
                        errors={errors}
                        items={productConditionList}
                        defaultValue={selectedProductCondition}
                        onChangeValue={(item) => {
                            setSelectedProductCondition(item);
                        }}
                    />

                    <div
                        className="mb-4 text-start"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className="mb-4 text-start space-x-4"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={false}
                                disabled={false}
                            >
                                {"Search"}
                            </Button>

                            <Button
                                variant={"secondary"}
                                onClick={() => {
                                    navigate(Routes.ProductManagementInventoryAddOrDetails);
                                }}
                                className={"ml-4"}
                                loading={false}
                                disabled={false}
                            >
                                {"Add new product"}
                            </Button>
                        </div>
                    </div>

                    <InventoryList
                        inventoryList={inventoryList}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onDeleteItem={(item: ProductManagementInventoryItem) => {
                            deleteItem(item);
                        }}
                    />
                </Card>
            </div>
        </>
    );
}
