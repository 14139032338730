import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import ProductManagementOfferAddView from "../views/productManagement/offer/details";

const ProductListingOfferRoutes = () => {
    return (
        <Routes>
            <Route
                path={"/"}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementOfferAddView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductListingPublishByOfferDetailsWithOfferId}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementOfferAddView />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default ProductListingOfferRoutes;
