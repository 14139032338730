import { useCallback, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import {
    BusinessInPersonServiceGridShowReq,
    BusinessInPersonServicesGridItem,
    BusinessInPersonServicesGridShowResponse,
    DescriptionGeneratorReq,
    DescriptionGeneratorResponse,
    SortOrder,
} from "../../types";
import Button from "../ui/button";
import { userStore } from "../../store";
import TextArea from "../ui/text-area";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAiAnalysisDescriptionGenerator } from "../../service/hook/analysis";
import Label from "../ui/label";
import InPersonServiceList from "./InPersonServicesList";
import { useModalAction } from "../../provider/modal.context";
import { useBusinessInPersonServicesGridShow } from "../../service/hook/business";

type FormValues = {
    input: string;
};

const defaultValues = {
    input: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function InPersonServicesForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { user, businessItem } = userStore((state) => state);
    const { openModal } = useModalAction();

    const [inPersonServicesItems, setInPersonServicesItems] = useState<
        BusinessInPersonServicesGridItem[]
    >([]);

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);

    const [aiResponse, setAiResponse] = useState<string>("");

    const {
        mutate: mutateBusinessInPersonGridShow,
        isLoading: isLoadingBusinessInPersonGridShow,
        serverError: serverErrorBusinessInPersonGridShow,
        setServerError: setServerErrorBusinessInPersonGridShow,
    } = useBusinessInPersonServicesGridShow();

    useEffect(() => {
        if (businessItem) getBusinessInPersonGrid();
    }, [businessItem]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    const getBusinessInPersonGrid = () => {
        let input: BusinessInPersonServiceGridShowReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
        };

        mutateBusinessInPersonGridShow(input, {
            onSuccess: (successData: BusinessInPersonServicesGridShowResponse) => {
                if (successData.final_result_code === "000") {
                    setInPersonServicesItems(successData.captured_data ?? []);
                }
            },
        });
    };

    const deleteItem = async (item: any) => {
        openModal("ProductManagement_Inventory_Delete", {
            item: { ...item },
            inPersonServices: true,
            callback: () => {
                getBusinessInPersonGrid();
            },
        });
    };

    function handlePagination(current: any) {
        setPage(current);
    }

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    title={"In-Person services "}
                    details={[
                        "This page provides you with the opportunity to highlight all the in-person services your business offers. This allows customers to effortlessly search and connect with your company based on their specific requirements.",
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />
                {/* InPersonServicesDetailsModal */}
                <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                    <InPersonServiceList
                        offerList={inPersonServicesItems}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onDeleteItem={(item) => {
                            deleteItem(item);
                        }}
                    />

                    <div className="mb-4 text-right space-x-4 mt-4">
                        <Button
                            variant={"secondary"}
                            size={"small"}
                            form={"addAspectDialog"}
                            loading={false}
                            onClick={() => {
                                openModal("InPersonServicesDetailsModal", {
                                    item: {},
                                    inputTextArea: true,
                                    modalTitle: "Signal word",
                                    maxLengthTitle: 30,
                                    maxLengthValue: 100,
                                    labelValue: "Description",
                                    placeholderValue: "Description",
                                    callback: (item: any) => {
                                        if (item?.refresh) {
                                            getBusinessInPersonGrid();
                                        }
                                    },
                                });
                            }}
                        >
                            {"New service"}
                        </Button>
                    </div>
                </Card>
            </div>
        </>
    );
}
