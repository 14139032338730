import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, CaptureDataOurSiteServiceTypeItem } from "../../types";
import Pagination from "../ui/pagination";

export interface IServiceType extends CaptureDataOurSiteServiceTypeItem {}

export type IProps = {
    serviceTypeList: IServiceType[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
};

const ServiceTypeList = ({
    serviceTypeList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
}: IProps) => {
    const { t } = useTranslation();

    let columns = [
        {
            title: t("table:table-item-service-1"),
            dataIndex: "service_1",
            key: "service_1",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (service1: boolean) => {
                return (
                    <span className="truncate whitespace-nowrap">
                        {service1 ? "True" : "False" ?? "-"}
                    </span>
                );
            },
        },
        {
            title: t("table:table-item-service-2"),
            dataIndex: "service_2",
            key: "service_2",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (service2: boolean) => {
                return (
                    <span className="truncate whitespace-nowrap">
                        {service2 ? "True" : "False" ?? "-"}
                    </span>
                );
            },
        },
        {
            title: t("table:table-item-service-3"),
            dataIndex: "service_3",
            key: "service_3",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (service3: boolean) => {
                return (
                    <span className="truncate whitespace-nowrap">
                        {service3 ? "True" : "False" ?? "-"}
                    </span>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={serviceTypeList}
                    rowKey="id"
                    scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default ServiceTypeList;
