import * as yup from 'yup';

export const cardBankValidationSchema = yup.object(
  {
    cardName: yup.string().required('Card name is required.'),
    cardNumber: yup.string().required('Card number is required.'),
    expireDateMonth: yup.string().required('Enter month of your card expire date.'),
    expireDateYear: yup.string().required('Enter year of your card expire date.'),
    cvv: yup.string().required('Enter your card cvv.'),
    cardType: yup.string().required('Card type is required.'),
    nameOfCard: yup.string().required('Enter name for this card is required.'),
  }
);
