import React from "react";
import { useTranslation } from "react-i18next";
import InPersonServicesForm from "../../../components/InPersonServices/InPersonServices";

export default function InPersonServicesView() {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
                <h1 className="text-lg font-semibold text-heading">
                    {`${t("common:sidebar-nav-item-setup-in-person")} / ${t(
                        "common:sidebar-nav-sub-item-in-person-service"
                    )}`}
                </h1>
            </div>
            <InPersonServicesForm />
        </>
    );
}
