import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, BusinessDetailItem } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { EditIcon } from "../icons/edit copy";

export interface IBusinessDetail extends BusinessDetailItem {}

export type IProps = {
    detailList: IBusinessDetail[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onEditItem: (item: IBusinessDetail) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const DetailList = ({
    detailList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onEditItem,
}: IProps) => {
    const { t } = useTranslation();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    let columns = [
        {
            title: t("table:table-item-title"),
            dataIndex: "business_name",
            key: "business_name",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (name: string) => {
                return <span className="truncate whitespace-nowrap">{name ?? "-"}</span>;
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 130,
            render: function Render(value: number, record: IBusinessDetail) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        {/* <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                        <div
                            style={{
                                width: "20px",
                            }}
                        /> */}
                        <button
                            onClick={() => {
                                onEditItem(record);
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={detailList}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default DetailList;
