import IconCS from "../../assets/icon/cs.png";
import IconCommunity from "../../assets/icon/community.svg";
import IconReferenceCode from "../../assets/icon/reference-code.png";
import { Link } from "react-router-dom";
import { Routes } from "../../config/routes";

export default function LinkList() {
    const item = (title: string, subtitle: string, image: any, link: string = "#") => {
        return (
            <Link to={link} className="cursor-pointer">
                <div className="rounded-lg p-2 w-full h-72 bg-stone-100">
                    <img
                        src={image}
                        alt={title}
                        style={{
                            width: "70px",
                            height: "70px",
                        }}
                    />

                    <br />

                    <span className="text-lg text-gray-900 font-bold mt-8">{title}</span>

                    <br />
                    <br />

                    <span className="text-base leading-6 text-gray-800">{subtitle}</span>
                </div>
            </Link>
        );
    };
    return (
        <div
            style={{
                marginTop: "80px",
                paddingLeft: "200px",
                paddingRight: "200px",
            }}
            className="w-full space-x-2"
        >
            <div>
                <div className="flex justify-between w-full mt-16 space-x-4">
                    {item(
                        "24/7 Chat Support",
                        "Get 24/7 chat support with our friendly customer service agent.",
                        IconCS
                    )}

                    {item(
                        "FAQs",
                        "View FAQs for detailed information and instructions on each step",
                        IconCommunity,
                        Routes.faq
                    )}

                    {item(
                        "Blog",
                        "Stay up to date with latest stories and comments.",
                        IconReferenceCode
                    )}
                </div>
            </div>
        </div>
    );
}
