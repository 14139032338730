import { useCallback, useEffect, useState } from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    BusinessDetailByIdItem,
    ProductManagementMarketPlaceListItem,
    ProductManagementMarketPlaceListResponse,
    ProductManagementMarketPlaceReq,
    ProductManagementPolicyCreateResponse,
    ProductManagementPolicyItem,
    ProductManagementPolicyListResponse,
    ProductManagementPolicyTypeItem,
    ProductManagementPolicyTypeListResponse,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useBusinessDetailCreate,
    useBusinessDetailUpdate,
    useGetBusinessDetailRowItem,
    useProductManagementMarketPlaceList,
    useProductManagementPolicyCrete,
    useProductManagementPolicyList,
    useProductManagementPolicyTypeList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { toast } from "react-toastify";
import PolicyList from "./policy-list";
import FileInput from "../ui/file-input";
import { useModalAction } from "../../provider/modal.context";
import { productPolicyValidationSchema } from "./policy-validation-schema";

function PolicyType({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
    name,
    error,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementPolicyTypeItem[];
    defaultValue?: ProductManagementPolicyTypeItem;
    onChangeValue: (item: ProductManagementPolicyTypeItem) => void;
    name?: string;
    error?: string;
}) {
    const { t } = useTranslation();
    const errorMessage: string = name ? (errors.policyType?.message as string) : "";

    return (
        <div className="mb-5">
            <Label>{t("form:input-label-policy-type")}</Label>
            <SelectInput
                name={name ? name : "policyType"}
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementPolicyTypeItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.policy_description}
                getOptionValue={(option: any) => option.policy_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

function MarketPlaceList({
    control,
    errors,
    items,
    isLoading,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementMarketPlaceListItem[];
    isLoading: boolean;
    defaultValue?: ProductManagementMarketPlaceListItem;
    onChangeValue: (item: ProductManagementMarketPlaceListItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.marketPlace?.message as string;

    return (
        <div className="mb-5 w-full">
            <Label className="text-left">{"Marketplace"}</Label>
            <SelectInput
                name="marketPlace"
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementMarketPlaceListItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => `${option.marketplace_name}`}
                getOptionValue={(option: any) => option.marketplace_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

type FormValues = {
    policyName: string;
    policyType: any;
    marketPlace: any;
    policyData: any;
};

const defaultValues = {
    policyName: "",
    policyType: "",
    marketPlace: "",
    policyData: {},
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateProductPolicyForm() {
    const { t } = useTranslation();
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [policyTypeList, setPolicyTypeList] = useState<ProductManagementPolicyTypeItem[]>([]);
    const [policyTypeItemSelected, setPolicyTypeItemSelected] = useState<
        ProductManagementPolicyTypeItem | undefined
    >(undefined);

    const [selectedPolicyTypeSearch, setSelectedPolicyTypeSearch] = useState<
        ProductManagementPolicyTypeItem | undefined
    >(undefined);

    const [marketPlaceList, setMarketPlaceList] = useState<ProductManagementMarketPlaceListItem[]>(
        []
    );

    const [selectedMarketPlace, setSelectedMarketPlace] = useState<
        ProductManagementMarketPlaceListItem | undefined
    >(undefined);

    const [addOrEditMode, setAddOrEditMode] = useState<boolean>(true);
    const [productPolicyList, setProductPolicyList] = useState<ProductManagementPolicyItem[]>();
    const [document, setDocument] = useState<any>();

    const [editItem, setEditItem] = useState<BusinessDetailByIdItem | null>();

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const [policyName, setPolicyName] = useState<string>("");
    const [isShowAddForm, setIsShowAddForm] = useState<boolean>(false);

    const {
        mutate: mutateProductMangementPolicy,
        isLoading: isLoadingProductMangementPolicy,
        serverError: serverErrorProductMangementPolicy,
        setServerError: setServerErrorProductMangementPolicy,
    } = useProductManagementPolicyList();

    const {
        mutate: mutateProductMangementPolicyType,
        isLoading: isLoadingProductMangementPolicyType,
        serverError: serverErrorProductMangementPolicyType,
        setServerError: setServerErrorProductMangementPolicyType,
    } = useProductManagementPolicyTypeList();

    const {
        mutate: mutateProductManagementMarketPlaceList,
        isLoading: isLoadingProductMangementMarketPlaceList,
        serverError: serverErrorProductMangementMarketPlaceList,
        setServerError: setServerErrorProductMangementMarketPlaceList,
    } = useProductManagementMarketPlaceList();

    function handlePagination(current: any) {
        setPage(current);
    }

    const resolver = useYupValidationResolver(productPolicyValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    useEffect(() => {
        getProductPolicyList();
        getMarketPlaceList();
    }, [businessItem]);

    const getProductPolicyList = (policyName?: string, policyType?: string) => {
        let input: any = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            page: 1,
            size: 20,
        };

        if (policyName) {
            input.policyName = policyName;
        }

        if (policyType) {
            input.policyType = policyType;
        }

        mutateProductMangementPolicy(input, {
            onSuccess: (successData: ProductManagementPolicyListResponse) => {
                if (successData.final_result_code === "000") {
                    setProductPolicyList(successData.captured_data);
                }
            },
        });
        if (policyTypeList.length === 0) {
            mutateProductMangementPolicyType(
                {},
                {
                    onSuccess: (successData: ProductManagementPolicyTypeListResponse) => {
                        if (successData.final_result_code === "000") {
                            setPolicyTypeList(successData.captured_data ?? []);
                        }
                    },
                }
            );
        }
    };

    const getMarketPlaceList = (id?: string) => {
        const input: ProductManagementMarketPlaceReq = {};

        if (id) {
            input.marketplace_id = id;
        }

        mutateProductManagementMarketPlaceList(input, {
            onSuccess: (successData: ProductManagementMarketPlaceListResponse) => {
                if (successData.final_result_code === "000") {
                    setMarketPlaceList(successData.captured_data ?? []);
                }
            },
        });
    };

    const formReset = (item?: BusinessDetailByIdItem) => {
        if (item) {
            reset({
                // isReceiveUpdate: item.accepted_to_get_updates_via_sms_email,
                // businessName: item.business_name,
                // type: item.business_type,
                // vatNumber: item.vat_registration_number_tax,
            });
            // getCountryItem(item.country ?? "", (country: CountryItem) => {
            //     setCountryItem(country);
            //     setValue("country", country);
            // });
        } else {
            reset(defaultValues);
            setPolicyTypeItemSelected(undefined);
        }
    };

    const deleteItem = async (item: any) => {
        openModal("ProductManagement_Delete_Policy", {
            item: { ...item },
            callback: () => {
                getProductPolicyList();
            },
        });
    };

    const onSubmit = async () => {
        let artPolicyName: any = undefined;
        let artPolicyType: any = undefined;
        if (policyName.length > 0) {
            artPolicyName = policyName;
        }
        if (selectedPolicyTypeSearch) {
            artPolicyType = selectedPolicyTypeSearch.policy_description;
        }
        getProductPolicyList(artPolicyName, artPolicyType);
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    // title={t("form:form-title-product-management-policy")}
                    details={[t("form:form-description-product-management-policy-01")]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3">
                    <Input
                        label={"Policy name"}
                        name="policyName"
                        value={policyName}
                        onChange={(e) => {
                            setPolicyName(e.target.value);
                        }}
                        error={t(errors.policyName?.message!)}
                        variant="outline"
                        className="mb-5"
                        maxLength={30}
                    />

                    <PolicyType
                        name={"policyTypeSearch"}
                        control={control}
                        errors={errors}
                        items={policyTypeList}
                        defaultValue={selectedPolicyTypeSearch}
                        onChangeValue={(item) => {
                            setSelectedPolicyTypeSearch(item);
                        }}
                    />

                    <MarketPlaceList
                        control={control}
                        errors={errors}
                        items={marketPlaceList}
                        isLoading={isLoadingProductMangementMarketPlaceList}
                        defaultValue={selectedMarketPlace}
                        onChangeValue={(item) => {
                            setSelectedMarketPlace(item);
                        }}
                    />

                    <div
                        className="mb-4 text-start"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className="mb-4 text-start"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={
                                    isLoadingProductMangementPolicy ||
                                    isLoadingProductMangementPolicyType
                                }
                                disabled={isLoadingProductMangementPolicy}
                            >
                                {"Search"}
                            </Button>
                            <Button
                                variant={"secondary"}
                                onClick={() => {
                                    openModal("ProductManagement_Add_Policy", {
                                        item: null,
                                        callback: () => {
                                            getProductPolicyList();
                                        },
                                    });
                                }}
                                className={"ml-4"}
                                loading={false}
                                disabled={
                                    isLoadingProductMangementPolicy ||
                                    isLoadingProductMangementPolicyType
                                }
                            >
                                {"Add new policy"}
                            </Button>
                        </div>
                    </div>

                    <PolicyList
                        policyList={productPolicyList}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onDeleteItem={(item: ProductManagementPolicyItem) => {
                            deleteItem(item);
                        }}
                    />
                </Card>
            </div>
        </>
    );
}
