import React from 'react';
export const Shirts: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.429"
      height="18"
      fill="currentColor"
      {...props}
    >
      <g data-name="Group 12550">
        <path
          data-name="Path 21722"
          d="M7.392 17.351a.322.322 0 01-.322-.321V5.876a1.314 1.314 0 01.366-.917A7.707 7.707 0 009.629.643H7.712a.322.322 0 110-.643h2.25a.321.321 0 01.321.321A7.785 7.785 0 017.902 5.4a.686.686 0 00-.19.475v11.154a.321.321 0 01-.32.322z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12551">
        <path
          data-name="Path 21723"
          d="M10.606 5.143a.322.322 0 01-.251-.121l-.643-.8-.01-.013a.59.59 0 00-.379-.221.772.772 0 00-.526.332.321.321 0 01-.492-.413 1.316 1.316 0 011.046-.562 1.205 1.205 0 01.868.48l.256.32.726-2.419L9.763.576a.321.321 0 01.4-.5l1.607 1.286a.321.321 0 01.107.343l-.964 3.214a.321.321 0 01-.308.229z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12552">
        <path
          data-name="Path 21724"
          d="M15.107 18h-2.572a.321.321 0 01-.321-.321v-1.892a.322.322 0 11.643 0v1.568h1.929V5.066a2.255 2.255 0 00-1.17-1.981l-2.2-1.2a.322.322 0 01.307-.565l2.2 1.2a2.9 2.9 0 011.505 2.545v12.613a.322.322 0 01-.321.322z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12553">
        <path
          data-name="Path 21725"
          d="M7.714 17.358c-2.613 0-3.5-.591-4.081-.981a1.243 1.243 0 00-.74-.3.322.322 0 01-.322-.321v-9.5a.322.322 0 01.643 0v9.216a2.2 2.2 0 01.776.38c.551.368 1.306.873 3.724.873s3.173-.5 3.724-.873a2.2 2.2 0 01.776-.38V6.256a.322.322 0 11.643 0v9.5a.322.322 0 01-.322.321 1.243 1.243 0 00-.74.3c-.584.39-1.468.981-4.081.981z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12554">
        <path
          data-name="Path 21726"
          d="M12.536 10.928H11.25a1.609 1.609 0 01-1.608-1.607V7.392a.322.322 0 01.322-.321h2.571a.322.322 0 110 .643h-2.25v1.607a.965.965 0 00.964.964h1.286a.322.322 0 110 .643z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12555">
        <path
          data-name="Path 21727"
          d="M7.714 5.451a.321.321 0 01-.236-.1A7.714 7.714 0 015.143.324a.321.321 0 01.321-.321h2.25a.322.322 0 010 .643H5.797a7.622 7.622 0 002.152 4.269.321.321 0 01-.236.54z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12556">
        <path
          data-name="Path 21728"
          d="M4.822 5.144a.321.321 0 01-.308-.229L3.55 1.701a.321.321 0 01.107-.343L5.264.072a.321.321 0 01.4.5l-1.438 1.15.726 2.419.256-.32a1.2 1.2 0 01.867-.48 1.315 1.315 0 011.046.562.321.321 0 01-.492.413.777.777 0 00-.526-.332.589.589 0 00-.38.223l-.009.011-.643.8a.321.321 0 01-.249.126z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12557">
        <path
          data-name="Path 21729"
          d="M2.893 18H.322a.321.321 0 01-.321-.321V5.066a2.9 2.9 0 011.5-2.545l2.2-1.2a.322.322 0 01.307.565l-2.2 1.2a2.254 2.254 0 00-1.169 1.98v12.291h1.928v-1.57a.322.322 0 01.643 0v1.89a.322.322 0 01-.317.323z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12558">
        <path
          data-name="Path 21730"
          d="M4.178 10.928H2.892a.322.322 0 110-.643h1.286a.965.965 0 00.964-.964V7.714h-2.25a.322.322 0 110-.643h2.572a.322.322 0 01.322.321v1.929a1.609 1.609 0 01-1.608 1.607z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12559">
        <path
          data-name="Path 21731"
          d="M8.679 7.071a.321.321 0 10-.321.321.321.321 0 00.321-.321z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12560">
        <path
          data-name="Path 21732"
          d="M8.679 9a.321.321 0 10-.321.321A.321.321 0 008.679 9z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12561">
        <path
          data-name="Path 21733"
          d="M8.679 10.928a.321.321 0 10-.321.321.321.321 0 00.321-.321z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12562">
        <path
          data-name="Path 21734"
          d="M8.679 12.857a.321.321 0 10-.321.321.321.321 0 00.321-.321z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12563">
        <path
          data-name="Path 21735"
          d="M8.679 14.785a.321.321 0 10-.321.321.321.321 0 00.321-.321z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12564">
        <path
          data-name="Path 21736"
          d="M15.107 16.072h-2.572a.322.322 0 010-.643h2.572a.322.322 0 110 .643z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12565">
        <path
          data-name="Path 21737"
          d="M2.893 16.072H.322a.322.322 0 010-.643h2.571a.322.322 0 110 .643z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
