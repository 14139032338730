import { useEffect, useState } from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    BusinessSectorCreateRequest,
    BusinessSectorShowResponse,
    CaptureDataBusinessServiceTypeItem,
    CaptureDataOurSiteServiceTypeItem,
    CaptureDataSectorItem,
    Category,
    JobCategoriesItem,
    JobCategoriesListResponse,
    JobTitleItem,
    JobTitleListResponse,
    SectorShowItem,
    SectorShowListResponse,
    ServiceTypeForBusinessItem,
    ServiceTypeForBusinessListResponse,
    SortOrder,
} from "../../types";
import Button from "../ui/button";
import Checkbox from "../ui/checkbox/checkbox";
import ServiceTypeList from "./service-type-list";
import {
    useBusinessBusinessSectorCreate,
    useBusinessBusinessSectorShow,
    useBusinessJobCategories,
    useBusinessJobTitle,
    useBusinessSectorShow,
    useBusinessServiceTypeForBusiness,
} from "../../service/hook/business";
import { toast } from "react-toastify";
import { userStore } from "../../store";
import ServiceSectorTypeList from "./service-type-list-sector";
import ServiceServiceTypeList from "./service-type-list-service-type";

type FormValues = {
    [key: string]: boolean | JobCategoriesItem | JobTitleItem | undefined | string | string[];
    isCommonProduct: boolean;
    isDedicatedProduct: boolean;
    isDedicatedService: boolean;
    jobCategory?: JobCategoriesItem | string | undefined;
    jobType?: JobTitleItem | string | undefined;
};

const defaultValues = {
    isCommonProduct: false,
    isDedicatedProduct: false,
    isDedicatedService: false,
    isFashion: false,
    isHealth: false,
    isElectronic: false,
    jobCategory: "",
    jobType: "",
};

type IProps = {
    initialValues?: Category | undefined;
};

function JobCategories({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: JobCategoriesItem[];
    defaultValue?: JobCategoriesItem;
    onChangeValue: (item: JobCategoriesItem) => void;
}) {
    const errorMessage: string = errors.jobCategory?.message as string;

    const { t } = useTranslation();
    return (
        <div
            className="mb-5"
            style={{
                width: "40%",
            }}
        >
            <Label>{t("form:form-label-job-categories")}</Label>
            <SelectInput
                name="jobCategory"
                control={control}
                value={defaultValue}
                onChange={(value: JobCategoriesItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.category_name}
                getOptionValue={(option: any) => option.category_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={errorMessage} />
        </div>
    );
}

function JobType({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: JobTitleItem[];
    defaultValue?: JobTitleItem;
    onChangeValue: (item: JobTitleItem) => void;
}) {
    const errorMessage: string = errors.jobCategory?.message as string;

    const { t } = useTranslation();
    return (
        <div
            className="mb-5"
            style={{
                width: "40%",
            }}
        >
            <Label>{t("form:form-label-job-type")}</Label>
            <SelectInput
                name="jobType"
                control={control}
                value={defaultValue}
                onChange={(value: JobTitleItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.job_name}
                getOptionValue={(option: any) => option.service_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={errorMessage} />
        </div>
    );
}

export default function CreateOrUpdateBusinessServiceTypeForm({ initialValues }: IProps) {
    const { t } = useTranslation();
    const { businessItem } = userStore((state) => state);

    const [serviceActiveList, setServiceActiveList] = useState<CaptureDataOurSiteServiceTypeItem[]>(
        []
    );
    const [dataSectorList, setDataSectorList] = useState<CaptureDataSectorItem[]>([]);
    const [businessServiceTypeList, setBusinessServiceTypeList] = useState<
        CaptureDataBusinessServiceTypeItem[]
    >([]);
    const [serviceTypeForBusinessList, setServiceTypeForBusinessList] = useState<
        ServiceTypeForBusinessItem[]
    >([]);

    const [sectorShowList, setSectorShowList] = useState<SectorShowItem[]>([]);
    const [jobCategoriesList, setJobCategories] = useState<JobCategoriesItem[]>([]);
    const [jobTypeList, setJobTypeList] = useState<JobTitleItem[]>([]);
    const [selectedSectorShowItems, setSelectedSectorShowItems] = useState<string[]>([]);

    const [jobCategoryItem, setJobCategory] = useState<JobCategoriesItem | undefined>(undefined);
    const [jobTypeItem, setJobTypeItem] = useState<JobTitleItem | undefined>(undefined);

    const [isCommonProduct, setIsCommonProduct] = useState(false);
    const [isDedicatedProduct, setIsDedicatedProduct] = useState(false);
    const [isDedicatedService, setIsDedicatedService] = useState(false);

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const {
        mutate: mutateBusinessServiceTypeForBusiness,
        isLoading: isLoadingBusinessmutateBusinessServiceTypeForBusiness,
        serverError: serverErrorBusinessmutateBusinessServiceTypeForBusiness,
        setServerError: setServerErrorErrorBusinessmutateBusinessServiceTypeForBusiness,
    } = useBusinessServiceTypeForBusiness();

    const {
        mutate: mutateBusinessSectorShow,
        isLoading: isLoadingBusinessmutateBusinessSectorShow,
        serverError: serverErrorBusinessmutateBusinessSectorShow,
        setServerError: setServerErrorErrorBusinessmutateBusinessSectorShow,
    } = useBusinessSectorShow();

    const {
        mutate: mutateBusinessJobCategories,
        isLoading: isLoadingBusinessmutateBusinessJobCategories,
        serverError: serverErrorBusinessmutateBusinessJobCategories,
        setServerError: setServerErrorErrorBusinessmutateBusinessJobCategories,
    } = useBusinessJobCategories();

    const {
        mutate: mutateBusinessJobType,
        isLoading: isLoadingBusinessmutateBusinessJobType,
        serverError: serverErrorBusinessmutateBusinessJobType,
        setServerError: setServerErrorErrorBusinessmutateBusinessJobType,
    } = useBusinessJobTitle();

    const {
        mutate: mutateBusinessBusinessSectorShow,
        isLoading: isLoadingBusinessmutateBusinessBusinessSectorShow,
        serverError: serverErrorBusinessmutateBusinessBusinessSectorShow,
        setServerError: setServerErrorErrorBusinessmutateBusinessBusinessSectorShow,
    } = useBusinessBusinessSectorShow();

    const {
        mutate: mutateBusinessBusinessSectorCreate,
        isLoading: isLoadingBusinessmutateBusinessBusinessSectorCreate,
        serverError: serverErrorBusinessmutateBusinessBusinessSectorCreate,
        setServerError: setServerErrorErrorBusinessmutateBusinessBusinessSectorCreate,
    } = useBusinessBusinessSectorCreate();

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        resetField,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: initialValues
            ? {
                  ...initialValues,
                  //   icon: initialValues?.icon
                  //       ? categoryIcons.find(
                  //             (singleIcon) => singleIcon.value === initialValues?.icon!
                  //         )
                  //       : "",
                  // ...(isNewTranslation && {
                  //     type: null,
                  // }),
              }
            : defaultValues,
        // resolver: yupResolver(businessSetupValidationSchema),
    });

    const isActiveServiceType1 = watch("isCommonProduct");
    const isActiveServiceType2 = watch("isDedicatedProduct");
    const isActiveServiceType3 = watch("isDedicatedService");

    // useEffect(() => {}, []);
    useEffect(() => {
        if (jobCategoryItem) {
            getJobType(jobCategoryItem.category_id);
            setJobTypeList([]);
            setJobTypeItem(undefined);
            // setValue("jobType", undefined);
            if (jobTypeItem)
                reset({
                    jobType: undefined,
                });
        }
    }, [jobCategoryItem]);

    useEffect(() => {
        if (isActiveServiceType1 || isActiveServiceType2)
            if (sectorShowList?.length === 0) {
                getSectorShowService();
            }

        if (isActiveServiceType3)
            if (jobCategoriesList?.length === 0) {
                getJobCategories();
            }
    }, [isActiveServiceType1, isActiveServiceType2, isActiveServiceType3]);

    useEffect(() => {
        getBusinessSectorShow();
        getBusinessServiceTypeForBusiness();
    }, [businessItem]);

    const getBusinessServiceTypeForBusiness = () => {
        mutateBusinessServiceTypeForBusiness(undefined, {
            onSuccess: (successData: ServiceTypeForBusinessListResponse) => {
                if (successData.final_result_code === "000") {
                    try {
                        setServiceTypeForBusinessList(successData.captured_data ?? []);
                    } catch (err) {
                        console.log("err => ", err);
                    }
                } else {
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    const getSectorShowService = () => {
        mutateBusinessSectorShow(undefined, {
            onSuccess: (successData: SectorShowListResponse) => {
                if (successData.final_result_code === "000") {
                    try {
                        setSectorShowList(successData.captured_data ?? []);
                    } catch (err) {
                        console.log("err => ", err);
                    }
                } else {
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    const getJobCategories = () => {
        mutateBusinessJobCategories(undefined, {
            onSuccess: (successData: JobCategoriesListResponse) => {
                if (successData.final_result_code === "000") {
                    try {
                        setJobCategories(successData.captured_data ?? []);
                    } catch (err) {
                        console.log("err => ", err);
                    }
                } else {
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    const getJobType = (category_id: string) => {
        mutateBusinessJobType(
            {
                category_id,
            },
            {
                onSuccess: (successData: JobTitleListResponse) => {
                    if (successData.final_result_code === "000") {
                        try {
                            setJobTypeList(successData.captured_data ?? []);
                        } catch (err) {
                            console.log("err => ", err);
                        }
                    } else {
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            }
        );
    };

    const getBusinessSectorShow = () => {
        mutateBusinessBusinessSectorShow(
            {
                businessdetail_id: businessItem?.business_id ?? 0,
            },
            {
                onSuccess: (successData: BusinessSectorShowResponse) => {
                    if (successData.final_result_code === "000") {
                        try {
                            setServiceActiveList(successData.captured_data_our_site_service_type);
                            setDataSectorList(successData.captured_data_sector);
                            setBusinessServiceTypeList(
                                successData.captured_data_business_service_type
                            );
                        } catch (err) {
                            console.log("err => ", err);
                        }
                    } else {
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            }
        );
    };

    const onSubmit = async (values: FormValues) => {
        const input: BusinessSectorCreateRequest = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            service_1: values.isCommonProduct ? "True" : "False",
            service_2: values.isDedicatedProduct ? "True" : "False",
            service_3: values.isDedicatedService ? "True" : "False",
            contract_id: "A3000", // static
            sector_ids_str: selectedSectorShowItems.join(","),
            service_id: `${jobTypeItem?.service_id}`,
            job_name: jobTypeItem?.job_name ?? "",
        };

        mutateBusinessBusinessSectorCreate(input, {
            onSuccess: (successData: any) => {
                if (successData.final_result_code === "000") {
                    try {
                        reset(defaultValues);
                        getBusinessSectorShow();
                    } catch (err) {
                        console.log("err => ", err);
                    }

                    toast.success(successData.general_result);
                } else {
                    toast.error(successData.general_result);
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
                // closeModal();
            },
        });
    };

    const addSelectSectorShowItems = (item: string) => {
        if (selectedSectorShowItems.length === 0) {
            setSelectedSectorShowItems([item]);
        } else {
            const findItem = selectedSectorShowItems.find((find) => find === `${item}`);
            if (!findItem) {
                setSelectedSectorShowItems([...selectedSectorShowItems, item]);
            }
        }
    };

    const removeSectorShowItems = (item: string) => {
        if (selectedSectorShowItems.length > 0) {
            const findItem = selectedSectorShowItems.find((find) => find === `${item}`);
            if (findItem) {
                setSelectedSectorShowItems(
                    selectedSectorShowItems.filter((filter) => filter !== item)
                );
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={t("form:form-title-business-service-type")}
                        details={[
                            t("form:form-description-business-service-type-02"),
                            t("form:form-description-business-service-type-03"),
                        ]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    {serviceTypeForBusinessList.length === 3 && (
                        <Card className="w-full sm:w-8/12 md:w-2/3">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Checkbox
                                    {...register("isCommonProduct")}
                                    label={`${serviceTypeForBusinessList[0].service_title ?? ""}`}
                                    description={`${serviceTypeForBusinessList[0].service_description}`}
                                    disabled={isActiveServiceType3}
                                    onChange={(e) => {
                                        resetField("isCommonProduct", {
                                            defaultValue: e.target.checked,
                                        });
                                        resetField("isDedicatedProduct", {
                                            defaultValue: e.target.checked,
                                        });
                                    }}
                                    className="mb-5"
                                />
                            </div>
                            <div
                                style={{
                                    // display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Checkbox
                                    {...register("isDedicatedProduct")}
                                    label={`${serviceTypeForBusinessList[1].service_title ?? ""}`}
                                    description={`${serviceTypeForBusinessList[1].service_description}`}
                                    disabled={isActiveServiceType3}
                                    onChange={(e) => {
                                        resetField("isDedicatedProduct", {
                                            defaultValue: e.target.checked,
                                        });
                                        resetField("isCommonProduct", {
                                            defaultValue: e.target.checked,
                                        });
                                    }}
                                    className="mb-5"
                                />
                                {(isActiveServiceType1 || isActiveServiceType2) &&
                                    sectorShowList.length > 0 && (
                                        <div
                                            style={{
                                                width: "100%",
                                                height: 200,
                                                maxHeight: 300,
                                                marginTop: 10,
                                                marginBottom: 25,
                                                overflow: "hidden",
                                                padding: "16px",
                                                overflowY: "scroll",
                                                borderColor: "gray",
                                                borderWidth: 0.7,
                                            }}
                                        >
                                            <Label>{"Select sector :"}</Label>
                                            {sectorShowList?.map(
                                                (sectorShowItem: SectorShowItem, index: number) => {
                                                    return (
                                                        <>
                                                            <Checkbox
                                                                {...register(
                                                                    `sectorShowItem${index}`
                                                                )}
                                                                label={sectorShowItem.sector_title}
                                                                value={sectorShowItem.sector_id}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        addSelectSectorShowItems(
                                                                            `${e.target.value}`
                                                                        );
                                                                    } else {
                                                                        removeSectorShowItems(
                                                                            `${e.target.value}`
                                                                        );
                                                                    }
                                                                }}
                                                                className="mb-5"
                                                            />
                                                        </>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                            </div>
                            <div
                                style={{
                                    // display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Checkbox
                                    {...register("isDedicatedService")}
                                    label={`${serviceTypeForBusinessList[2].service_title ?? ""}`}
                                    description={`${serviceTypeForBusinessList[2].service_description}`}
                                    disabled={isActiveServiceType1 || isActiveServiceType2}
                                    className="mb-5"
                                />

                                {isActiveServiceType3 && (
                                    <div
                                        style={{
                                            width: "100%",
                                            height: 150,
                                            maxHeight: 150,
                                            marginTop: 10,
                                            marginBottom: 25,
                                            // overflow: "hidden",
                                            padding: "16px",
                                            // overflowY: "scroll",
                                            borderColor: "gray",
                                            borderWidth: 0.7,
                                        }}
                                    >
                                        {/* <Label>{"Select sector :"}</Label> */}

                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <JobCategories
                                                control={control}
                                                errors={errors}
                                                items={jobCategoriesList}
                                                defaultValue={jobCategoryItem}
                                                onChangeValue={setJobCategory}
                                            />

                                            <JobType
                                                control={control}
                                                errors={errors}
                                                items={jobTypeList}
                                                defaultValue={jobTypeItem}
                                                onChangeValue={setJobTypeItem}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card>
                    )}
                </div>

                <div className="mb-4 text-end">
                    <Button loading={isLoadingBusinessmutateBusinessBusinessSectorCreate}>
                        {t("form:button-label-update")}
                    </Button>
                </div>
            </form>

            <ServiceTypeList
                serviceTypeList={serviceActiveList}
                paginatorInfo={null}
                onPagination={handlePagination}
                onOrder={setOrder}
                onSort={setColumn}
            />

            {serviceActiveList.length > 0 &&
                (serviceActiveList[0].service_1 || serviceActiveList[0].service_2) && (
                    <ServiceSectorTypeList
                        serviceSectorTypeList={dataSectorList}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                    />
                )}
            {serviceActiveList.length > 0 && serviceActiveList[0].service_3 && (
                <ServiceServiceTypeList
                    serviceServiceTypeList={businessServiceTypeList}
                    paginatorInfo={null}
                    onPagination={handlePagination}
                    onOrder={setOrder}
                    onSort={setColumn}
                />
            )}
        </>
    );
}
