export const RefundsIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.736 16" {...props}>
    <g fill="currentColor">
      <path
        data-name="Path 1"
        d="M7.863 5.466v-.757h-.867v.757l-.115.014c-.456.055-1.945.34-1.945 1.69 0 1.332 1.085 1.562 2.4 1.708.721.085 1.678.284 1.678 1.026 0 .677-.638 1.1-1.666 1.1a2.151 2.151 0 0 1-1.829-.894l-.81.343a2.584 2.584 0 0 0 2.172 1.321l.119.011v.832h.867v-.878l.121-.009a1.922 1.922 0 0 0 1.979-1.821c0-1.394-1.162-1.651-2.567-1.824-.7-.083-1.561-.186-1.561-.911 0-.613.562-.965 1.541-.965a2.083 2.083 0 0 1 1.545.641l.736-.34a2.111 2.111 0 0 0-1.688-1.027Z"
      />
      <path
        data-name="Path 2"
        d="M2.848 2.809a.344.344 0 1 1 .422.543A6.683 6.683 0 1 0 7.001 1.96l.806.806a.344.344 0 0 1-.487.486L5.744 1.676 7.32.101a.344.344 0 1 1 .486.486l-.68.68a7.367 7.367 0 0 1 1.382 14.642A7.462 7.462 0 0 1 7.347 16a7.371 7.371 0 0 1-4.5-13.191Z"
      />
    </g>
  </svg>
);
