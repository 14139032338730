import { useCallback, useEffect, useState } from "react";
import { Control, FieldErrors, FieldValues, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import {
    AvailabilityTypeModel,
    BranchLocationItem,
    PackageDeliveryTypeItem,
    PickUpAtLocationListItemModel,
    ProductManagementImage,
    ProductManagementInventoryBrandItem,
    ProductManagementInventoryDetails,
    ProductManagementInventoryItem,
    ProductManagementInventoryProductConditionItem,
    ProductManagementPolicyTypeItem,
    ProductManagementVideoItem,
    SortOrder,
    ProductManagementInventoryGroupCreateOrUpdateReq,
    ProductManagementInventoryGroupCreateOrUpdateResponse,
    ProductManagementInventoryGroupDetailsReq,
    ProductManagementInventoryGroupDetailsResponse,
    ProductManagementInventoryGroupDetails,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import {
    useProductManagementInventoryGroupCreateOrUpdate,
    useProductManagementInventoryGroupDetails,
} from "../../service/hook/sector_1";
import { inventoryAddValidationSchema } from "./inventory-add-validation-schema";
import TextArea from "../ui/text-area";
import AddInventoryAspectsList from "./inventory-add-form-aspects-list";
import { inventoryPickUpAtLocationValidationSchema } from "./inventory-pickup-at-location-validation-schema";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import InventoryGroupSkuList from "./inventoryGroup-productSku-list";

type FormValues = {
    skuGroup: string;
    productTitle: string;
    productSubtitle: string;
    description: string;
    aspectVariesBy: string;
};

const defaultValues = {
    skuGroup: "",
    productTitle: "",
    productSubtitle: "",
    description: "",
    aspectVariesBy: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateProductInventoryGroupDetailsForm({
    isEditMode,
}: {
    isEditMode?: boolean;
}) {
    const navigate = useNavigate();
    const params = useParams();

    console.log("params => ", params);

    const { t } = useTranslation();
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [selectedProductItems, setSelectedProductItems] = useState<
        ProductManagementInventoryItem[]
    >([]);

    const [selectedSpecificationsList, setSelectedSpecificationsList] = useState<
        AvailabilityTypeModel[]
    >([]);

    const [brandList, setBrandList] = useState<ProductManagementInventoryBrandItem[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<
        ProductManagementInventoryBrandItem | undefined
    >(undefined);

    const [productConditionList, setProductConditionList] = useState<
        ProductManagementInventoryProductConditionItem[]
    >([]);
    const [selectedProductCondition, setSelectedProductCondition] = useState<
        ProductManagementInventoryProductConditionItem | undefined
    >(undefined);

    const [inventoryList, setInventoryList] = useState<ProductManagementInventoryItem[]>([]);
    const [policyTypeItemSelected, setPolicyTypeItemSelected] = useState<
        ProductManagementPolicyTypeItem | undefined
    >(undefined);

    const [pickUpAtLocationList, setPickUpAtLocationList] = useState<
        PickUpAtLocationListItemModel[]
    >([]);

    const [shipToLocationList, setShipToLocationList] = useState<PickUpAtLocationListItemModel[]>(
        []
    );

    const [packageDeliveryTypeList, setPackageDeliveryTypeList] = useState<
        PackageDeliveryTypeItem[]
    >([]);
    const [selectedPackageDeliveryTypeItem, setSelectedPackageDeliveryTypeItem] = useState<
        PackageDeliveryTypeItem | undefined
    >();

    const [branchLocationList, setBranchLocationList] = useState<BranchLocationItem[]>([]);
    const [selectedBranchLocationItem1, setSelectedBranchLocationItem1] = useState<
        BranchLocationItem | undefined
    >();
    const [selectedBranchLocationItem2, setSelectedBranchLocationItem2] = useState<
        BranchLocationItem | undefined
    >();

    const [
        selectedAvailabilityTypePickupAtLocationItem,
        setSelectedAvailabilityTypePickupAtLocationItem,
    ] = useState<AvailabilityTypeModel | undefined>();

    const [
        selectedAvailabilityTypePickupAtLocationItem2,
        setSelectedAvailabilityTypePickupAtLocationItem2,
    ] = useState<AvailabilityTypeModel | undefined>();

    const [
        selectedAvailabilityTypePickupAtLocationUnitTypeItem,
        setSelectedAvailabilityTypePickupAtLocationUnitTypeItem,
    ] = useState<AvailabilityTypeModel | undefined>();
    const [
        selectedAvailabilityTypePickupAtLocationUnitTypeItem2,
        setSelectedAvailabilityTypePickupAtLocationUnitTypeItem2,
    ] = useState<AvailabilityTypeModel | undefined>();

    const [selectedLengthUnitItem, setSelectedLengthUnitItem] = useState<
        AvailabilityTypeModel | undefined
    >();

    const [selectedWeightUnitItem, setSelectedWeightUnitItem] = useState<
        AvailabilityTypeModel | undefined
    >();

    const [selectedProductImageList, setSelectedProductImageList] = useState<
        ProductManagementImage[]
    >([]);
    const [selectedProductVideoList, setSelectedProductVideoList] = useState<
        ProductManagementVideoItem[]
    >([]);
    const [selectedProductAspectList, setSelectedProductAspectList] = useState<
        AvailabilityTypeModel[]
    >([]);

    const [inventoryGroupDetails, setInventoryGroupDetails] = useState<
        ProductManagementInventoryGroupDetails | undefined
    >();

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);

    const [isShowFormPickupAtLocation, setIsShowFormPickupAtLocation] = useState<boolean>(false);
    const [isShowFormShipToLocation, setIsShowFormShipToLocation] = useState<boolean>(false);

    const resolver = useYupValidationResolver(inventoryAddValidationSchema);

    const resolverPickUpAtLocation = useYupValidationResolver(
        inventoryPickUpAtLocationValidationSchema
    );

    const {
        mutate: mutateProductManagementInventoryGroupCreateOrUpdate,
        isLoading: isLoadingProductMangementInventoryGroupCreateOrUpdate,
        serverError: serverErrorProductMangementInventoryGroupCreateOrUpdate,
        setServerError: setServerErrorProductMangementInventoryGroupCreateOrUpdate,
    } = useProductManagementInventoryGroupCreateOrUpdate();

    const {
        mutate: mutateProductManagementInventoryGroupDetials,
        isLoading: isLoadingProductMangementInventoryGroupDetials,
        serverError: serverErrorProductMangementInventoryGroupDetials,
        setServerError: setServerErrorProductMangementInventoryGroupDetials,
    } = useProductManagementInventoryGroupDetails();

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        setError,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    useEffect(() => {
        getData();
    }, [businessItem]);

    useEffect(() => {
        const { skuGroupCode } = params;
        if (skuGroupCode) {
            if (skuGroupCode) {
                getProductInventoryGroupItem(skuGroupCode);
            }
        }
    }, []);

    const getData = async () => {};

    const getProductInventoryGroupItem = async (skuGroupCode: string) => {
        const input: ProductManagementInventoryGroupDetailsReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
        };

        if (skuGroupCode) {
            input.sku_group_code = skuGroupCode;
        }

        await mutateProductManagementInventoryGroupDetials(input, {
            onSuccess: (successData: ProductManagementInventoryGroupDetailsResponse) => {
                if (successData.final_result_code === "000") {
                    setInventoryGroupDetails(successData.captured_data);
                    formReset(successData.captured_data);
                }
            },
        });
    };

    const formReset = (item?: ProductManagementInventoryGroupDetails) => {
        if (item) {
            reset({
                skuGroup: item.sku_group_code,
                productTitle: item.product_title,
                productSubtitle: item.product_subtitle,
                description: item.product_description,
                aspectVariesBy: item.varies_by.aspectsImageVariesBy.join(", "),
            });

            if (item.variant_sku) {
                setSelectedProductItems(
                    item.variant_sku.map((mapItem) => {
                        return {
                            product_inventory_id: 0,
                            sku_code: mapItem,
                            product_condition_id: 0,
                            product_condition_description: "",
                            brand_id: null,
                            brand: "",
                            product_title: "",
                            offer_status: "",
                            _updated_at: "",
                        };
                    })
                );
            }

            if (item.varies_by) {
                setSelectedSpecificationsList(
                    item.varies_by.specifications.map((mapItem) => {
                        return {
                            title: mapItem.name,
                            value: mapItem.values.join(", "),
                        };
                    })
                );
            }

            // if (item.varies_by) {
            //     reset({ aspectVariesBy: item.varies_by.aspectsImageVariesBy.join(", ") });
            // }

            // if (item?.product_aspects) {
            //     const aspectKeys = Object.keys(item.product_aspects) ?? [];
            //     const aspectValues = Object.values(item.product_aspects) ?? [];

            //     if (aspectKeys.length > 0 && aspectValues.length > 0)
            //         setSelectedProductAspectList(
            //             aspectKeys?.map((mapItem: string, index) => {
            //                 const item: AvailabilityTypeModel = {
            //                     id: index + 1,
            //                     title: aspectKeys[index],
            //                     value: aspectValues[index] as string,
            //                 };
            //                 return item;
            //             }) ?? []
            //         );
            // }
        } else {
            reset(defaultValues);
        }
    };

    const deleteItem = async (item: ProductManagementInventoryItem) => {
        setSelectedProductItems(
            selectedProductItems.filter((filterItem) => filterItem.sku_code !== item.sku_code)
        );
    };

    const onSubmit = async (values: FormValues) => {
        try {
            const productAspect: any = {};
            for (let i = 0; i < selectedProductAspectList.length; i++) {
                const aspectItem = selectedProductAspectList[i];
                productAspect[aspectItem.title] = aspectItem.value;
            }

            const input: ProductManagementInventoryGroupCreateOrUpdateReq = {
                email: user?.email ?? "",
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
                sku_group_code: values.skuGroup,
                product_description: values.description,
                product_title: values.productTitle,
                product_subtitle: values.productSubtitle,
                variant_sku: selectedProductItems.map((mapItem) => mapItem.sku_code),
                varies_by: {
                    aspectsImageVariesBy: values.aspectVariesBy.split(","),
                    specifications: selectedSpecificationsList.map((mapItem) => {
                        return {
                            name: mapItem.title,
                            values: (mapItem.value.split(",") ?? []).map((mapItem: string) => {
                                return mapItem.trim();
                            }),
                        };
                    }),
                },
            };

            if (params.skuGroupCode) {
                input.product_inventory_group_id =
                    inventoryGroupDetails?.product_inventory_group_id ?? 0;
            }

            mutateProductManagementInventoryGroupCreateOrUpdate(input, {
                onSuccess: (successData: ProductManagementInventoryGroupCreateOrUpdateResponse) => {
                    if (successData.final_result_code === "000") {
                        toast.success(successData.general_result);
                        navigate(-1);
                    } else {
                        toast.error(successData.general_result);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            });
        } catch (error) {
            console.log("error => ", error);
        }
    };

    return (
        <>
            <form id={"inventory-add-form"} onSubmit={handleSubmit(onSubmit)} className="">
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Enter product details information "}
                        details={[
                            t("form:form-description-product-management-add-inventory-group-01"),
                        ]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <Input
                            label={"Product(SKU) group code "}
                            {...register("skuGroup")}
                            placeholder="SKU"
                            error={t(errors.skuGroup?.message!)}
                            variant="outline"
                            maxLength={70}
                        />

                        <Input
                            label={"Product title"}
                            {...register("productTitle")}
                            placeholder="Product title"
                            error={t(errors.productTitle?.message!)}
                            variant="outline"
                            maxLength={75}
                        />

                        <Input
                            label={"Product subtitle"}
                            {...register("productSubtitle")}
                            placeholder="Product subtitle"
                            error={t(errors.productSubtitle?.message!)}
                            variant="outline"
                            maxLength={60}
                        />

                        <TextArea
                            label={t("form:input-label-description")}
                            {...register("description")}
                            error={t(errors.description?.message!)}
                            variant="outline"
                            placeholder="Description"
                            className="mb-5"
                            maxLength={4000}
                        />

                        <Label>{"Product codes"}</Label>

                        <InventoryGroupSkuList
                            inventoryList={selectedProductItems}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: ProductManagementInventoryItem) => {
                                deleteItem(item);
                            }}
                        />

                        <Button
                            variant={"secondary"}
                            size={"small"}
                            form={"findSKUModal"}
                            className="mt-5"
                            loading={false}
                            onClick={() => {
                                openModal("ProductManagement_Offer_Add_SKU_From_SKUList", {
                                    item: {},
                                    multiSelect: true,
                                    callback: (
                                        selectedProductItems: ProductManagementInventoryItem[]
                                    ) => {
                                        setSelectedProductItems(selectedProductItems);
                                    },
                                });
                            }}
                        >
                            {"Find"}
                        </Button>
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Varies by"}
                        details={
                            [
                                // t("form:form-description-product-management-add-inventory-group-01"),
                            ]
                        }
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <Input
                            label={"Aspects image varies by"}
                            {...register("aspectVariesBy")}
                            placeholder="Aspects image varies by ..."
                            error={t(errors.aspectVariesBy?.message!)}
                            variant="outline"
                            note="(Color, Size)"
                        />

                        <Label>{"Specifications"}</Label>

                        <AddInventoryAspectsList
                            imageList={selectedSpecificationsList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: AvailabilityTypeModel) => {
                                setSelectedSpecificationsList(
                                    selectedSpecificationsList.filter((filterItem) => {
                                        return filterItem.id !== item.id;
                                    })
                                );
                            }}
                            onPreviewItem={(item: AvailabilityTypeModel) => {
                                openModal(
                                    "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                    {
                                        item: {},
                                        isPreview: true,
                                        valueTitle: item.title,
                                        valueValue: item.value,
                                        inputTextArea: true,
                                        modalTitle: "Statements",
                                        maxLengthTitle: 30,
                                        maxLengthValue: 150,
                                        labelValue: "Description",
                                        placeholderValue: "Description",
                                        callback: (item: AvailabilityTypeModel) => {
                                            setSelectedSpecificationsList([
                                                ...selectedSpecificationsList,
                                                {
                                                    ...item,
                                                    id: new Date().getTime(),
                                                },
                                            ]);
                                        },
                                    }
                                );
                            }}
                            options={{
                                title1: "Name",
                                title2: "Values",
                            }}
                            isShowPreview
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addAspectDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                        {
                                            item: {},
                                            inputTextArea: true,
                                            modalTitle: "Specifications",
                                            placeholderTitle: "ex: Size",
                                            maxLengthTitle: 30,
                                            labelTitle: "Name",
                                            labelValue: "Values",
                                            placeholderValue: "ex: S, M, L, XL, 2XL, 3Xl",
                                            callback: (item: AvailabilityTypeModel) => {
                                                console.log("item => ", item);

                                                setSelectedSpecificationsList([
                                                    ...selectedSpecificationsList,
                                                    {
                                                        ...item,
                                                        id: new Date().getTime(),
                                                    },
                                                ]);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new"}
                            </Button>
                        </div>
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col ">
                        <div className="mb-4 text-center space-x-4 mt-4">
                            <Button
                                variant={"cancelForm"}
                                form={"backToList"}
                                loading={false}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                {t("form:button-label-cancel")}
                            </Button>

                            {(!params.skuGroupCode || isEditMode) && (
                                <Button
                                    form={"inventory-add-form"}
                                    loading={isLoadingProductMangementInventoryGroupCreateOrUpdate}
                                    disabled={isLoadingProductMangementInventoryGroupCreateOrUpdate}
                                >
                                    {params.skuGroupCode ? "Update" : t("form:button-label-submit")}
                                </Button>
                            )}
                        </div>
                    </Card>
                </div>
            </form>
        </>
    );
}
