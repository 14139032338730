export const DiaryIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.199"
    height="20.2"
    viewBox="0 0 16.199 20.2"
    {...props}
  >
    <path
      data-name="Path 3652"
      d="M14.854.1H2.806A2.628 2.628 0 0 0 .1 2.617v14.966A2.628 2.628 0 0 0 2.806 20.1h12.056a1.289 1.289 0 0 0 .887-.331 1.121 1.121 0 0 0 .352-.81V1.264A1.21 1.21 0 0 0 14.854.1Zm-5.346.952h2.994v4.467l-1.063-1.583a.494.494 0 0 0-.175-.163.53.53 0 0 0-.7.163L9.508 5.512ZM1.132 2.617a1.629 1.629 0 0 1 1.674-1.565h5.683v4.857a.864.864 0 0 0 .634.857 1.012 1.012 0 0 0 1.091-.5l.792-1.19.792 1.19a1.029 1.029 0 0 0 1.1.5.864.864 0 0 0 .634-.852v-4.85h1.328a.21.21 0 0 1 .214.2v13.809a1.327 1.327 0 0 0-.214-.016H2.806a2.813 2.813 0 0 0-1.674.545Zm13.947 14.495-11.75.012a.477.477 0 1 0 0 .952l11.75-.012v.886a.2.2 0 0 1-.061.143.225.225 0 0 1-.153.057H2.806a1.569 1.569 0 1 1 0-3.131h12.056a.21.21 0 0 1 .214.2v.893Z"
      fill="currentColor"
      stroke="#fff"
      strokeWidth=".2"
    />
  </svg>
);
