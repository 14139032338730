import { useTranslation } from "react-i18next";
import CreateOrUpdateProductOfferForm from "../../../components/productManagement/offer-form";

export default function ProductManagementOfferView({ children, layout }: any) {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
                <h1 className="text-lg font-semibold text-heading">
                    {`${t("common:sidebar-nav-item-product-management")} / ${t(
                        "common:sidebar-nav-sub-item-product-offer"
                    )}`}
                </h1>
            </div>
            <CreateOrUpdateProductOfferForm />
        </>
    );
}
