import { useUI } from "../../../contexts/ui.context";
import AuthorizedMenu from "./authorized-menu";
import { NavbarIcon } from "../../icons/navbar-icon";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Logo from "../../ui/logo";
import LinkButton from "../../ui/link-button";
import { adminAndOwnerOnly, getAuthCredentials, hasAccess } from "../../../utils/auth-utils";

const Navbar = () => {
    const { t } = useTranslation();
    const { toggleSidebar } = useUI();

    const { permissions } = getAuthCredentials();

    return (
        <header className="fixed z-40 w-full bg-white shadow">
            <nav className="flex items-center justify-between px-5 py-4 md:px-8">
                {/* <!-- Mobile menu button --> */}
                <motion.button
                    whileTap={{ scale: 0.88 }}
                    onClick={toggleSidebar}
                    className="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none lg:hidden"
                >
                    <NavbarIcon />
                </motion.button>

                <div className="ms-5 me-auto hidden md:flex">
                    <Logo />
                </div>

                <div className="space-s-8 flex items-center">
                    {hasAccess(adminAndOwnerOnly, permissions) && (
                        <LinkButton href={"#"} className="ms-4 md:ms-6" size="small">
                            {t("common:text-create-shop")}
                        </LinkButton>
                    )}
                    {/* {enableMultiLang ? <LanguageSwitcher /> : null} */}
                    <AuthorizedMenu />
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
