import React from "react";
import { useTranslation } from "react-i18next";
import CreateOrUpdateBusinessBranchLocationForm from "../../../components/businessSetup/branch-location-form";

export default function BusinessSetupBranchView({ children, layout }: any) {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
                <h1 className="text-lg font-semibold text-heading">
                    {`${t("common:sidebar-nav-item-setup-business")} / ${t(
                        "common:sidebar-nav-sub-item-branch-location"
                    )}`}
                </h1>
            </div>
            <CreateOrUpdateBusinessBranchLocationForm />
        </>
    );
}
