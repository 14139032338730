import { useMemo } from "react";
// import { useRouter } from 'next/router';
// import { useSettings } from "@/framework/settings";

export function formatPrice({
    amount,
    currencyCode,
    locale,
    fractions,
}: {
    amount: number;
    currencyCode: string;
    locale: string;
    fractions: number;
}) {
    const formatCurrency = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        maximumFractionDigits: fractions,
    });

    return formatCurrency.format(amount);
}

export function formatVariantPrice({
    amount,
    baseAmount,
    currencyCode,
    locale,
    fractions = 2,
}: {
    baseAmount: number;
    amount: number;
    currencyCode: string;
    locale: string;
    fractions: number;
}) {
    const hasDiscount = baseAmount > amount;
    const formatDiscount = new Intl.NumberFormat(locale, { style: "percent" });
    const discount = hasDiscount ? formatDiscount.format((baseAmount - amount) / baseAmount) : null;

    const price = formatPrice({ amount, currencyCode, locale, fractions });
    const basePrice = hasDiscount
        ? formatPrice({ amount: baseAmount, currencyCode, locale, fractions })
        : null;

    return { price, basePrice, discount };
}

type PriceProps = {
    amount: number;
    baseAmount?: number;
    currencyCode?: string;
};

export function usePrice(data?: PriceProps | null) {
    const currency = "GBP";
    const currencyOptions = {
        formation: "en-UK",
        fractions: 2,
    };
    const { formation, fractions } = currencyOptions;
    const { amount, baseAmount, currencyCode = currency } = data ?? {};
    const locale = formation ?? "en";
    const value = useMemo(() => {
        if (typeof amount !== "number" || !currencyCode) return "";

        return baseAmount
            ? formatVariantPrice({ amount, baseAmount, currencyCode, locale, fractions })
            : formatPrice({ amount, currencyCode, locale, fractions });
    }, [amount, baseAmount, currencyCode]);

    return typeof value === "string" ? { price: value, basePrice: null, discount: null } : value;
}
