import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, ProductManagementInventoryGroupItem } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { EditIcon } from "../icons/edit";
import { TrashIcon } from "../icons/trash";
import { Eye } from "../icons/eye-icon";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";

export interface IProductInventoryGroup extends ProductManagementInventoryGroupItem {}

export type IProps = {
    inventoryList: IProductInventoryGroup[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IProductInventoryGroup) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const InventoryGroupList = ({
    inventoryList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    let columns = [
        {
            title: "SKU group code",
            dataIndex: "sku_group_code",
            key: "sku_group_code",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Product title",
            dataIndex: "product_title",
            key: "product_title",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Updated",
            dataIndex: "_updated_at",
            key: "_updated_at",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Action",
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 130,
            render: function Render(value: number, record: IProductInventoryGroup) {
                return (
                    <div
                        className="space-x-8"
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            onClick={() => {
                                navigate(
                                    `${Routes.ProductManagementInventoryGroupAddOrDetails}${record.sku_group_code}`
                                );
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={"View"}
                        >
                            <Eye width={16} />
                        </button>
                        <button
                            onClick={() => {
                                navigate(
                                    `${Routes.ProductManagementInventoryGroupUpdateBase}${record.sku_group_code}`
                                );
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button>

                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={inventoryList}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default InventoryGroupList;
