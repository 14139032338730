import { Navigate, useNavigate } from "react-router-dom";
// import { authStore, userStore } from "../../stores";
// import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { authStore, userStore } from "../../store";
import { useUser } from "../../service/hook/user";
import { UserShowResponse } from "../../types";
import { Routes } from "../../config/routes";

interface AuthorizedRouteProps {
    route: {
        requiredRoles: string[];
        resource: string;
        action: string;
    } | null;

    userRole: any;
    children: React.ReactNode;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({ route, userRole, children }) => {
    const navigate = useNavigate();

    const { user, setUser } = userStore((state) => state);
    const { accessToken, email, isAuthorized, setAccessToken, setAuthorized } = authStore(
        (state) => state
    );

    // useEffect(() => {
    //     if (accessToken === "") {
    //         wipeData();
    //     }
    // }, [accessToken]);

    // const hasRequiredRole = userRole?.roles?.some((role: any) => {
    //     return (
    //         route?.requiredRoles.includes(role.title) &&
    //         role.permissions?.some((permission: any) => {
    //             return permission.resource === route.resource && permission.action === route.action;
    //         })
    //     );
    // });

    const wipeData = () => {
        localStorage.clear();
        sessionStorage.clear();
        setAuthorized(false);
        setAccessToken("");
        navigate(Routes.login);
    };

    return <>{children}</>;
};

export default AuthorizedRoute;
