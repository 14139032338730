import React from 'react';
export const Dairy: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.967"
      height="22.1"
      viewBox="0 0 16.967 22.1"
      fill="currentColor"
      {...props}
    >
      <g id="Dairy" transform="translate(-71.95 -15.95)">
        <path
          id="Path_17395"
          data-name="Path 17395"
          d="M72.733,36.167v-8.8a8.516,8.516,0,0,1,.693-3.374l.755-1.762c.064-.15.126-.3.183-.46a6.94,6.94,0,0,1,2.722.055,7.585,7.585,0,0,0,2.869.085c.041.107.085.214.13.321l.755,1.763.674-.289-.755-1.763c-.08-.188-.154-.379-.22-.568a8.55,8.55,0,0,1-.465-2.44h.359a.367.367,0,0,0,.367-.367V17.1A1.1,1.1,0,0,0,79.7,16H74.567a1.1,1.1,0,0,0-1.1,1.1v1.467a.367.367,0,0,0,.367.367h.359a8.552,8.552,0,0,1-.45,2.4c-.071.207-.15.412-.235.61L72.752,23.7A9.247,9.247,0,0,0,72,27.367v8.8A1.835,1.835,0,0,0,73.833,38H79.7v-.733H73.833A1.1,1.1,0,0,1,72.733,36.167ZM74.2,17.1a.367.367,0,0,1,.367-.367H79.7a.367.367,0,0,1,.367.367v1.1H74.2Zm5.141,1.833a9.271,9.271,0,0,0,.375,2.27,6.855,6.855,0,0,1-2.47-.095,7.673,7.673,0,0,0-2.633-.118,9.278,9.278,0,0,0,.314-2.057Z"
          transform="translate(0 0)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.1"
        />
        <path
          id="Path_17397"
          data-name="Path 17397"
          d="M241.533,201.833c0-1.2-2.4-1.833-4.767-1.833s-4.767.63-4.767,1.833a.88.88,0,0,0,.01.132l1.092,11.271a.367.367,0,0,0,.365.33h6.6a.367.367,0,0,0,.365-.33l1.092-11.271A.884.884,0,0,0,241.533,201.833Zm-1,3-.185.062a5.813,5.813,0,0,1-3.526.053,6.566,6.566,0,0,0-3.778,0l-.033.01-.181-2.047a10.662,10.662,0,0,0,7.873,0Zm-3.767-4.1c2.593,0,4.015.717,4.032,1.093l0,.046c-.091.382-1.506,1.061-4.028,1.061s-3.937-.679-4.028-1.061l0-.046C232.752,201.451,234.173,200.733,236.767,200.733Zm2.968,12.1H233.8l-.718-7.128.175-.052a5.859,5.859,0,0,1,3.357,0,6.539,6.539,0,0,0,3.845-.02Z"
          transform="translate(-152.667 -175.567)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.1"
        />
      </g>
    </svg>
  );
};
