export const termsAndServices = {
  title: 'terms-main-title',
  date: '01/03/2024',
  content2: [
    {
      id: '1',
      title: 'terms-one-title',
      description: 'terms-one-description',
    },
    {
      id: '2',
      title: 'terms-two-title',
      description: 'terms-two-description',
    },
    {
      id: '3',
      title: 'terms-three-title',
      description: 'terms-three-description',
    },
    {
      id: '4',
      title: 'terms-four-title',
      description: 'terms-four-description',
    },
    {
      id: '5',
      title: 'terms-five-title',
      description: 'terms-five-description',
    },
    {
      id: '6',
      title: 'terms-six-title',
      description: 'terms-six-description',
    },
  ],
  content: [
    {
      id: '1',
      title: 'terms-s1-title',
      description: 'terms-s1-content',
    },
    {
      id: '2',
      title: 'terms-s2-title',
      description: 'terms-s2-content',
    },
    {
      id: '3',
      title: 'terms-s3-title',
      description: 'terms-s3-content',
    },
    {
      id: '4',
      title: 'terms-s4-title',
      description: 'terms-s4-content',
    },
    {
      id: '5',
      title: 'terms-s5-title',
      description: 'terms-s5-content',
    },
    {
      id: '6',
      title: 'terms-s6-title',
      description: 'terms-s6-content',
    },
    {
      id: '7',
      title: 'terms-s7-title',
      description: 'terms-s7-content',
    },
    {
      id: '8',
      title: 'terms-s8-title',
      description: 'terms-s8-content',
    },
    {
      id: '9',
      title: 'terms-s9-title',
      description: 'terms-s9-content',
    },
    {
      id: '10',
      title: 'terms-s10-title',
      description: 'terms-s10-content',
    }
  ],
};
