import Slider from "../../components/landing/slider";
import MarketPlace from "../../components/landing/marketPlace";
import Benefits from "../../components/landing/benefits";
import GetStarted from "../../components/landing/getStarted";
import LinkList from "../../components/landing/linkList";

export default function Landing() {
    return (
        <div className="pb-20">
            <Slider />
            <MarketPlace />
            <Benefits />
            <GetStarted />
            <LinkList />
        </div>
    );
}
