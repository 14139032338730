import React from "react";
import AuthPageLayout from "../../components/layouts/auth-layout";
import { useTranslation } from "react-i18next";
import LoginForm from "../../components/auth/login-form";

export default function LoginView({ children, layout }: any) {
    const { t } = useTranslation("common");

    return (
        <AuthPageLayout>
            <h3 className="mb-6 mt-4 text-center text-base italic text-body">
                {t("admin-login-title")}
            </h3>
            <LoginForm />
        </AuthPageLayout>
    );
}
