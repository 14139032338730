import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalAction } from '../../provider/modal.context';
import { useMutation, useQuery } from '@tanstack/react-query';
import client from "../client";
import { useAtom } from 'jotai';
import { authorizationAtom } from '../../store/authorization-atom';
import { useToken } from '../../utils/hooks/use-token';
import { API_ENDPOINTS } from '../client/api-endpoints';
import axios from 'axios';
import { userInfoAtom } from '../../store/user-atom';
import { authStore, userStore } from '../../store';
import { RegisterUserInput } from '../../types';
import { toast } from 'react-toastify';

export function useLogin() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.users.login, {
        onSuccess: (data) => {

            if (!data.token) {
                setServerError('error-credential-wrong');
                return;
            }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                // toast.error(`${data?.email}`);
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useLogin2() {
    const { setAccessToken, setEmail: setEmailStorage } = authStore((state) => state);
    const { t } = useTranslation('common');
    const { closeModal } = useModalAction();
    const { setToken } = useToken();
    const { mutate: getUser, isLoading: userIsLoading, serverError: userServerError, setServerError: usrSetServerError } = useUser();
    let [serverError, setServerError] = useState<string | null>(null);
    let [email, setEmail] = useState<string>('');

    const { mutate, isLoading } = useMutation(client.users.login, {
        onSuccess: (data) => {

            if (!data.token) {
                setServerError('error-credential-wrong');
                return;
            }
            setToken(data.token);
            setAccessToken(data.token);
            setEmailStorage(email);
            getUser({ email: email })
            closeModal();
        },
        onError: (error: Error) => {
            console.log(error.message);
        },
    });

    return { mutate, isLoading, serverError, setServerError, setEmail };
}

export function useUser() {
    const { setEmailVerified, getEmailVerified } = useToken();
    const { emailVerified } = getEmailVerified();
    const { setUser } = userStore((state) => state);
    let [serverError, setServerError] = useState<string | null>(null);
    // const router = useRouter();

    const { mutate, isLoading } = useMutation(client.users.me_v2, {
        onSuccess: (data) => {

            if (data?.final_result_code === "000") {
                setUser({
                    email: data?.captured_data?.email ?? '',
                    firstName: data?.captured_data?.first_name ?? '',
                    lastName: data?.captured_data?.last_name ?? '',
                    isConfirmEmail: data?.captured_data?.email_confirmed ?? false
                })
            }

        },
        onError: (error: Error) => {
            console.log(error.message);
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 409) {
                    setEmailVerified(false);
                    // router.push(Routes.verifyEmail);
                    return;
                }
                // if (router.pathname === Routes.verifyEmail) {
                //     return;
                // }
            }
        },
    });

    //TODO: do some improvement here
    return { mutate, isLoading, serverError, setServerError };
}

export function useVerifyEmail() {
    const { t } = useTranslation('common');
    const { closeModal } = useModalAction();
    const { setEmail, setAuthorized } = authStore((state) => state);
    const { setUser } = userStore((state) => state);
    let [formError, setFormError] = useState<Partial<RegisterUserInput> | string | null>(
        null
    );

    const { mutate, isLoading } = useMutation(client.users.verifyEmail, {
        onSuccess: (data) => {

            if (data.final_result_code === "000") {
                toast.success('Your emil confirm successfully.')
            }
        },
        onError: (error) => {

            const {
                response: { data },
            }: any = error ?? {};

            if (data) {
                if (data?.final_result_code === '001') {
                    toast.error(`${data?.general_result}`);
                }
            }

            setFormError(data);
        },
    });

    return { mutate, isLoading };
}

export function useRegister() {
    const { t } = useTranslation('common');
    const { closeModal } = useModalAction();
    const { setEmail, setAuthorized } = authStore((state) => state);
    const { setUser } = userStore((state) => state);
    let [formError, setFormError] = useState<Partial<RegisterUserInput> | string | null>(
        null
    );

    const { mutate, isLoading } = useMutation(client.users.register, {
        onSuccess: (data) => {

            if (data.final_result_code === "000" && data.general_result === "user created") {

                if (!data.captured_data.email_confirmed) {
                    setAuthorized(true);
                    setEmail(data.captured_data.email)
                    // setUser({ email: data.captured_data.email, isConfirmEmail: data.captured_data.email_confirmed })

                    toast.success(`Your user has been successfully created.\nLog in with the information you have registered, in the next step you must enter the confirmation code sent to your email.`);
                } else {
                    toast.success(`${data.general_result}`);
                    closeModal();
                }
            }
        },
        onError: (error) => {

            const {
                response: { data },
            }: any = error ?? {}


            if (data) {
                if (data?.email) {
                    toast.error(data?.email[0] ?? '');
                }
            }

            setFormError(data);
        },
    });

    return { mutate, isLoading, formError, setFormError };
}