import * as yup from 'yup';

export const branchLocationValidationSchema = yup.object(
  {
    addressLine1: yup.string().required('form:error-input-address-line-1-required'),
    city: yup.string().required('form:error-input-city-required'),
    postalCode: yup.string().required('form:error-input-postal-code-required'),
    country: yup.object().required(),
    addressLine2: yup.string(),
    countyOpt: yup.string(),
    stateOrProvince: yup.string(),
    branchTitle: yup.string(),
    locationType: yup.string(),
    locationStatus: yup.object(),
    openTime: yup.string(),
    closeTime: yup.string(),
    phone: yup.string(),
  }
);