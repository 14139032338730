export const GDPR = {
  title: 'gdpr-main-title',
  date: '01/03/2024',
  content: [
    {
      id: '1',
      title: 'gdpr-s1-title',
      description: 'gdpr-s1-content',
    },
    {
      id: '2',
      title: 'gdpr-s2-title',
      description: 'gdpr-s2-content',
    },
    {
      id: '3',
      title: 'gdpr-s3-title',
      description: 'gdpr-s3-content',
    },
    {
      id: '4',
      title: 'gdpr-s4-title',
      description: 'gdpr-s4-content',
    },
    {
      id: '5',
      title: 'gdpr-s5-title',
      description: 'gdpr-s5-content',
    },
    {
      id: '6',
      title: 'gdpr-s6-title',
      description: 'gdpr-s6-content',
    },
    {
      id: '7',
      title: 'gdpr-s7-title',
      description: 'gdpr-s7-content',
    },
    {
      id: '8',
      title: 'gdpr-s8-title',
      description: 'gdpr-s8-content',
    },
    {
      id: '9',
      title: 'gdpr-s9-title',
      description: 'gdpr-s9-content',
    },
    {
      id: '10',
      title: 'gdpr-s10-title',
      description: 'gdpr-s10-content',
    },
    {
      id: '11',
      title: 'gdpr-s11-title',
      description: 'gdpr-s11-content',
    },
  ],
};
