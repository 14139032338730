import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useModalAction } from "../../provider/modal.context";
import type { LoginResponse, LoginUserInput, UserShowResponse } from "../../types";
import Alert from "../ui/alert";
import Form from "../ui/forms/form";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import Logo from "../ui/logo";
import PasswordInput from "../ui/forms/password-input";
import { useLogin, useUser } from "../../service/hook/user";
import { authStore, userStore } from "../../store";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";
// import { AnonymousIcon } from '@/components/icons/anonymous-icon';
// import { useRouter } from 'next/router';
// import { Routes } from '@/config/routes';
// import { useSettings } from '@/framework/settings';

const loginFormSchema = yup.object().shape({
    username: yup.string().email("form:error-email-format").required("form:error-email-required"),
    password: yup.string().required("form:error-password-required"),
});
function LoginForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const {
        setAccessToken,
        email,
        setEmail: setEmailStorage,
        setAuthorized,
        isAuthorized,
    } = authStore((state) => state);
    const { user, setUser } = userStore((state) => state);
    const { openModal, closeModal } = useModalAction();
    // const { settings, isLoading: settingLoading } = useSettings();
    // const isCheckout = router.pathname.includes("checkout");
    const { mutate: login, isLoading, serverError, setServerError } = useLogin();
    const {
        mutate: getUserMutate,
        isLoading: userIsLoading,
        serverError: userServerError,
        setServerError: usrSetServerError,
    } = useUser();

    useEffect(() => {
        if (isAuthorized && !userIsLoading) getUserInfo(email);
    }, [isAuthorized]);

    useEffect(() => {
        if (user !== null) {
            if (!user.isConfirmEmail) {
                navigate(Routes.verifyEmail, { replace: true });
            } else {
                navigate("/admin" + Routes.dashboard);
                // navigate(Routes.dashboard);
            }
        }
    }, [user]);

    function onSubmit({ username, password }: LoginUserInput) {
        login(
            {
                username,
                password,
            },
            {
                onSuccess: (successData: LoginResponse) => {
                    setAuthorized(true);
                    setAccessToken(successData.token);
                    setEmailStorage(username);
                },
            }
        );
    }

    const getUserInfo = (email: string) => {
        getUserMutate(
            {
                email,
            },
            {
                onSuccess: (data: UserShowResponse) => {
                    setUser({
                        firstName: data.captured_data.first_name,
                        lastName: data.captured_data.last_name,
                        email: data.captured_data.email,
                        isConfirmEmail: data.captured_data.email_confirmed,
                    });
                },
            }
        );
    };

    return (
        <>
            <Alert
                variant="error"
                message={serverError && t(serverError)}
                className="mb-6"
                closeable={true}
                onClose={() => setServerError(null)}
            />

            <Form<LoginUserInput> validationSchema={loginFormSchema} onSubmit={onSubmit}>
                {({ register, formState: { errors } }) => (
                    <>
                        <Input
                            label={t("form:input-label-email")}
                            {...register("username")}
                            type="email"
                            variant="outline"
                            className="mb-4"
                            error={t(errors?.username?.message!)}
                        />
                        <PasswordInput
                            label={t("form:input-label-password")}
                            // forgotPassHelpText={t("form:input-forgot-password-label")}
                            {...register("password")}
                            error={t(errors?.password?.message!)}
                            variant="outline"
                            className="mb-4"
                            // forgotPageLink={Routes.forgotPassword}
                        />
                        <Button className="w-full" loading={isLoading} disabled={isLoading}>
                            {t("form:button-label-login")}
                        </Button>

                        <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8">
                            <hr className="w-full" />
                            <span className="absolute -top-2.5 bg-light px-2 -ms-4 start-2/4">
                                {t("common:text-or")}
                            </span>
                        </div>

                        <div className="text-center text-sm text-body sm:text-base">
                            {t("form:text-no-account")}{" "}
                            <Link
                                to={Routes.register}
                                className="font-semibold text-accent underline transition-colors duration-200 ms-1 hover:text-accent-hover hover:no-underline focus:text-accent-700 focus:no-underline focus:outline-none"
                            >
                                {t("form:link-register-shop-owner")}
                            </Link>
                        </div>
                    </>
                )}
            </Form>
            {errorMessage ? (
                <Alert
                    message={t(errorMessage)}
                    variant="error"
                    closeable={true}
                    className="mt-5"
                    onClose={() => setErrorMessage(null)}
                />
            ) : null}
        </>
    );
}

export default function LoginView() {
    return (
        <>
            <LoginForm />
        </>
    );
}
