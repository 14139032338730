import React, { useEffect } from "react";
import { useUser } from "../../service/hook/user";
import { authStore, userStore } from "../../store";
import { UserShowResponse } from "../../types";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";

interface MainContextComponentProps {
    children: React.ReactNode;
}

const MainContextComponent = ({ children }: MainContextComponentProps) => {
    const navigate = useNavigate();
    const { mutate: getUserMutate } = useUser();
    const { user, setUser } = userStore((state) => state);
    const { accessToken, email, isAuthorized, setAccessToken, setAuthorized } = authStore(
        (state) => state
    );

    useEffect(() => {
        if (accessToken !== "" && isAuthorized) {
            if (user === null) {
                getUserInfo(email);
            }
        } else {
            console.log("accessToken else for wipe data ");
            console.log("wipe data");
            wipeData();
        }
    }, [accessToken, user]);

    const wipeData = () => {
        localStorage.clear();
        sessionStorage.clear();
        setAuthorized(false);
        setAccessToken("");
        navigate(Routes.landing);
    };

    const getUserInfo = (email: string) => {
        getUserMutate(
            {
                email,
            },
            {
                onSuccess: (data: UserShowResponse) => {
                    // if (!data.captured_data.email_confirmed) {
                    //     navigate(Routes.verifyEmail, { replace: true });
                    // }

                    setUser({
                        firstName: data.captured_data.first_name,
                        lastName: data.captured_data.last_name,
                        email: data.captured_data.email,
                        isConfirmEmail: data.captured_data.email_confirmed,
                    });
                },
            }
        );
    };

    return (
        <div className="flex min-h-screen flex-col bg-gray-100 transition-colors duration-150">
            {children}
        </div>
    );
};

export default MainContextComponent;
