import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import { Category } from "../../types";
import { userStore } from "../../store";
import { CheckMark } from "../icons/checkmark";
import { usePrice } from "../../utils/use-price";
import Description from "../ui/description";
import { useLocation, useNavigate } from "react-router-dom";
import Radio from "../ui/radio/radio";
import { useState } from "react";
import { useForm } from "react-hook-form";

type FormValues = {
    subscriptionType: string;
};

const defaultValues = {
    subscriptionType: "1",
};

type IProps = {
    initialValues?: Category | undefined;
};

const subscriptionItemServices = [
    "Max number of product to manage",
    "Max number of product images",
    "Max number of product videos",
    "Payment service",
    "Accounting",
    "Reporting",
    "Advanced Reporting",
    "AI Copilot",
    "7/24 support",
    "Financial support and Credit",
    "Report builder",
    "Order management",
];

const subscriptionItemInPerson = [
    "Basic reporting", //1
    "Advanced Reporting",
    "service management",
    "customer communication",
    "AI Ad campaign", //5
    "Payment processing",
    "Booking management",
    "7/24 support",
    "Financial support and Credit",
    "Report builder", //10
    "Order management",
    "advanced and attractive web template",
    "Reputation Management: customer reviews", //13
];

const subscriptionItems = [
    {
        title: "Basic",
        description: "For small Sole Traders, managing small product , basic listing and reports.",
        price: "5.00",
        price6m: "26.00",
        services: [
            {
                value: 10, // 'Max number of product to manage'
            },
            {
                value: 200, // 'Max number of product images',
            },
            {
                value: 20, // 'Max number of product videos',
            },
            {
                value: true, // 'Payment service',
            },
            {
                value: false, // 'Accounting',
            },
            {
                value: true, // 'Reporting',
            },
            {
                value: false, // 'Advanced Reporting',
            },
            {
                value: false, // 'AI Copilot',
            },
            {
                value: false, // '7/24 support',
            },
            {
                value: false, // 'Financial support and Credit',
            },
            {
                value: false, // 'Report builder',
            },
            {
                value: true, // 'Order management'
            },
        ],
    },
    {
        title: "Essential",
        description:
            "	For small businesses, having  about 100 products . Listing , Repoting , web profile , basic accounting , financial support as credit .",
        price: "21.77",
        price6m: "112.00",
        services: [
            {
                value: 100, // 'Max number of product to manage'
            },
            {
                value: 2000, // 'Max number of product images',
            },
            {
                value: 200, // 'Max number of product videos',
            },
            {
                value: true, // 'Payment service',
            },
            {
                value: true, // 'Accounting',
            },
            {
                value: true, // 'Reporting',
            },
            {
                value: true, // 'Advanced Reporting',
            },
            {
                value: false, // 'AI Copilot',
            },
            {
                value: false, // '7/24 support',
            },
            {
                value: true, // 'Financial support and Credit',
            },
            {
                value: false, // 'Report builder',
            },
            {
                value: true, // 'Order management'
            },
        ],
    },
    {
        title: "Plus",
        description:
            "For small and medium businesses, having  about high numbers of products . Listing , in-person services , Repoting , AI copilot, web profile,  accounting , financial support as credit , full support. ",
        price: "42.77",
        price6m: "140.00",
        services: [
            {
                value: 2000, // 'Max number of product to manage'
            },
            {
                value: 40000, // 'Max number of product images',
            },
            {
                value: 2000, // 'Max number of product videos',
            },
            {
                value: true, // 'Payment service',
            },
            {
                value: true, // 'Accounting',
            },
            {
                value: true, // 'Reporting',
            },
            {
                value: true, // 'Advanced Reporting',
            },
            {
                value: true, // 'AI Copilot',
            },
            {
                value: true, // '7/24 support',
            },
            {
                value: true, // 'Financial support and Credit',
            },
            {
                value: false, // 'Report builder',
            },
            {
                value: true, // 'Order management'
            },
        ],
    },
    {
        title: "Advanced",
        description:
            "For medium businesses, having  about high numbers of products . Listing , in-person services , Repoting, AI copilot, web profile, basic accounting , financial support as credit , advanced reporting , full support.",
        price: "135.67",
        price6m: "770.00",
        services: [
            {
                value: 10000, // 'Max number of product to manage'
            },
            {
                value: 120000, // 'Max number of product images',
            },
            {
                value: 20000, // 'Max number of product videos',
            },
            {
                value: true, // 'Payment service',
            },
            {
                value: true, // 'Accounting',
            },
            {
                value: true, // 'Reporting',
            },
            {
                value: true, // 'Advanced Reporting',
            },
            {
                value: true, // 'AI Copilot',
            },
            {
                value: true, // '7/24 support',
            },
            {
                value: true, // 'Financial support and Credit',
            },
            {
                value: true, // 'Report builder',
            },
            {
                value: true, // 'Order management'
            },
        ],
    },
];

const subscriptionItemsInPerson = [
    {
        title: "Basic",
        description: "For small Sole Traders, managing and showcasing basic listing and reports.",
        price: "50.00",
        price6m: "270.00",
        services: [
            {
                value: true, // 'Basic reporting'
            },
            {
                value: false, // 'Advanced Reporting',
            },
            {
                value: true, // 'service management',
            },
            {
                value: true, // 'customer comunication',
            },
            {
                value: false, // 'AI Ad compaign',
            },
            {
                value: true, // 'Payment processing',
            },
            {
                value: false, // 'Booking management',
            },
            {
                value: false, // '7/24 support',
            },
            {
                value: false, // 'Financial support and Credit',
            },
            {
                value: false, // 'Report builder',
            },
            {
                value: false, // 'Order management',
            },
            {
                value: false, // 'advanced and attarctive web template'
            },
            {
                value: false, // 'Reputation Management: customer reviews'
            },
        ],
    },
    {
        title: "Standard",
        description:
            "For small businesses, showcasing services , Reporting , web profile , financial support as credit.",
        price: "110.00",
        price6m: "620.00",
        services: [
            {
                value: true, // 'Basic reporting'
            },
            {
                value: true, // 'Advanced Reporting',
            },
            {
                value: true, // 'service management',
            },
            {
                value: true, // 'customer comunication',
            },
            {
                value: true, // 'AI Ad compaign',
            },
            {
                value: true, // 'Payment processing',
            },
            {
                value: true, // 'Booking management',
            },
            {
                value: true, // '7/24 support',
            },
            {
                value: true, // 'Financial support and Credit',
            },
            {
                value: false, // 'Report builder',
            },
            {
                value: false, // 'Order management',
            },
            {
                value: false, // 'advanced and attarctive web template'
            },
            {
                value: false, // 'Reputation Management: customer reviews'
            },
        ],
    },
    {
        title: "Premium",
        description:
            "For small, medium and large businesses, having  about high numbers of products .  in-person services , Reporting , AI copilot, web profile,  financial support as credit , full support.",
        price: "160.00",
        price6m: "910.00",
        services: [
            {
                value: true, // 'Basic reporting'
            },
            {
                value: true, // 'Advanced Reporting',
            },
            {
                value: true, // 'service management',
            },
            {
                value: true, // 'customer comunication',
            },
            {
                value: true, // 'AI Ad compaign',
            },
            {
                value: true, // 'Payment processing',
            },
            {
                value: true, // 'Booking management',
            },
            {
                value: true, // '7/24 support',
            },
            {
                value: true, // 'Financial support and Credit',
            },
            {
                value: true, // 'Report builder',
            },
            {
                value: true, // 'Order management',
            },
            {
                value: true, // 'advanced and attarctive web template'
            },
            {
                value: true, // 'Reputation Management: customer reviews'
            },
        ],
    },
];

export default function SubscriptionForm2({ initialValues }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { businessItem } = userStore((state) => state);

    const [radioValue, setRadioValue] = useState<string>("");

    console.log("location => ", location);

    const {
        register,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    const { price } = usePrice({
        amount: 4.5,
    });

    const subscriptionType = watch("subscriptionType");

    console.log("subscriptionType => ", subscriptionType);

    return (
        <>
            <div className="my-5 flex flex-wrap border-b border-dashed border-border-base pb-8 sm:my-8">
                <div className="w-full flex items-center justify-center">
                    <div className="w-3/3 items-center text-left  border-0 border-sky-400 divide-y rounded-lg px-8 py-4">
                        <Description
                            title={"Offers"}
                            listStyleType="dick-none"
                            titleStyle={"mb-8"}
                            details={[
                                "1.Tier upgrades: Users can upgrade to a higher tier at any time.",
                                "2.Discounts: Discounts may be available for annual subscriptions or bulk purchases.",
                                "3.Customized pricing: Custom pricing may be considered for businesses with unique requirements.",
                                "4.Payment options: Credit card, debit card, and PayPal payments are accepted.",
                                "5.Cancellation policy: Subscriptions can be cancelled at any time, but no refunds will be issued for partial months of service.",
                                "6.BAME group and Women entrepreneur will get a 35% discount.",
                            ]}
                            className="px-0 pb-5 sm:py-8 sm:pe-4 md:pe-5"
                        />
                    </div>
                </div>
                <Card className="w-full sm:w-8/12 md:w-full mt-28 bg-sky-50">
                    <form
                        id={"business_profile_form"}
                        // onSubmit={handleSubmit(onSubmit)}
                        className=""
                    >
                        <div className={"w-full flex items-center justify-center space-x-8 py-8"}>
                            <Radio
                                label={"Online-Product-Seller"}
                                {...register("subscriptionType")}
                                id="subType1"
                                value={"1"}
                                className="mt-4"
                            />

                            {/* <div className="w-8" /> */}

                            <Radio
                                label={"In-Person-Service-provider"}
                                {...register("subscriptionType")}
                                id="subType2"
                                value={"2"}
                                className="mt-4"
                            />
                        </div>
                    </form>

                    <div className="w-full ">
                        <div
                            className={`grid ${
                                subscriptionType === "1" ? "grid-cols-5" : "grid-cols-4"
                            } gap-1 text-center`}
                        >
                            <div className=""></div>
                            {(subscriptionType === "1"
                                ? subscriptionItems
                                : subscriptionItemsInPerson
                            ).map((mapItem, index) => {
                                return (
                                    <div
                                        className={`border rounded-lg overflow-hidden ${
                                            subscriptionType === "1"
                                                ? index === 2
                                                    ? "border-2 border-b-0 rounded-b-none border-slate-800"
                                                    : ""
                                                : index === 1
                                                ? "border-2 border-b-0 rounded-b-none border-slate-800"
                                                : ""
                                        }`}
                                    >
                                        <div className="w-full h-auto justify-center bg-slate-50 h-96 px-8 py-10 space-y-6">
                                            <div className="flex w-full items-center justify-center">
                                                <p className="text-2xl font-bold text-slate-900">
                                                    {mapItem.title}
                                                </p>
                                            </div>
                                            <div className="flex w-full  min-h-64">
                                                <span className="text-lg font-light text-slate-900">
                                                    {mapItem.description}
                                                </span>
                                            </div>
                                            <div className="mx-8">
                                                <div className="flex w-full h-8 items-center justify-evenly bg-stone-200 rounded-lg overflow-hidden">
                                                    <span className="w-1/2 text-sm font-semibold text-slate-900 text-center">
                                                        {"Monthly"}
                                                    </span>
                                                    <span className="w-1/2 text-sm font-semibold text-slate-50 py-1 text-center bg-slate-950 rounded-lg">
                                                        {"Annual"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex w-full items-center justify-center">
                                                    <p className="text-xl font-bold text-slate-900">
                                                        {"£"}
                                                    </p>
                                                    <p className="text-5xl font-bold text-slate-900">
                                                        {`${mapItem.price6m}`}
                                                    </p>
                                                    <p className="text-xl font-bold text-slate-900">
                                                        {"/6mo"}
                                                    </p>
                                                </div>

                                                <div className="flex w-full items-center justify-center">
                                                    <p className="text-lg font-bold text-slate-900">
                                                        {"£"}
                                                    </p>
                                                    <p className="text-3xl font-bold text-slate-900">
                                                        {`${mapItem.price}`}
                                                    </p>
                                                    <p className="text-xl font-bold text-slate-900">
                                                        {"/mo"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex w-full items-center justify-center ">
                                                <p
                                                    className="text-xl font-medium text-slate-900 bg-green-500 px-6 py-4 rounded-lg"
                                                    onClick={() => {
                                                        navigate(-1);
                                                    }}
                                                >
                                                    {"Choose plan"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            <div className="">
                                {(subscriptionType === "1"
                                    ? subscriptionItemServices
                                    : subscriptionItemInPerson
                                ).map((mapItem, index) => {
                                    return (
                                        <p
                                            className={`text-slate-900 ${
                                                index % 2 === 0 ? " bg-blue-100" : "bg-slate-100"
                                            } ${
                                                subscriptionType === "1" ? "text-base" : "text-lg"
                                            }`}
                                            style={{
                                                overflow: "hidden",
                                                maxLines: 1,
                                                height:
                                                    subscriptionType === "1"
                                                        ? "1.75rem"
                                                        : undefined,
                                            }}
                                        >
                                            {mapItem}
                                        </p>
                                    );
                                })}
                            </div>
                            {(subscriptionType === "1"
                                ? subscriptionItems
                                : subscriptionItemsInPerson
                            ).map((mapItem, index) => {
                                return (
                                    <div className="border rounded-sm ">
                                        {mapItem.services.map((serviceItem, indexServiceItem) => {
                                            return (
                                                <p
                                                    className={`text-lg text-body text-center text-slate-900 min-h-7 ${
                                                        indexServiceItem % 2 === 0
                                                            ? " bg-blue-100"
                                                            : "bg-slate-100"
                                                    }`}
                                                >
                                                    {typeof serviceItem.value === "boolean" ? (
                                                        serviceItem.value ? (
                                                            <div
                                                                style={{
                                                                    // textAlign: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                                className="flex w-full text-center"
                                                            >
                                                                <CheckMark width={14} />
                                                            </div>
                                                        ) : (
                                                            <>-</>
                                                        )
                                                    ) : (
                                                        serviceItem.value
                                                    )}
                                                </p>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Card>
            </div>

            <div className="mb-4 text-end"></div>
        </>
    );
}
