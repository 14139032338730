import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, BusinessDocumentItem } from "../../types";
import Pagination from "../ui/pagination";
import { TrashIcon } from "../icons/trash";

export interface IDocuments extends BusinessDocumentItem {}

export type IProps = {
    documents: IDocuments[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IDocuments) => void;
};

const DocumentList = ({
    documents,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
}: IProps) => {
    const { t } = useTranslation();

    const alignLeft = "left";
    const alignRight = "right";

    let columns = [
        {
            title: t("table:table-item-title"),
            dataIndex: "file_name",
            key: "file_name",
            width: 180,
            align: "center",
            ellipsis: true,
            render: (name: string) => {
                return (
                    <span className="truncate whitespace-nowrap">
                        {`${name}` ?? "-"}
                        {/* {`${name.substring(0, 30)}...` ?? "-"} */}
                    </span>
                );
            },
        },
        {
            title: t("table:table-item-file"),
            dataIndex: "file_urls",
            key: "file_urls",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (fielUrls: any[]) => {
                if (fielUrls.length > 0) {
                    return (
                        <span
                            onClick={() => {
                                window.open(fielUrls[0]?.url, "_blank");
                            }}
                            className="truncate whitespace-nowrap"
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            {"Download"}
                        </span>
                    );
                }
                return <span className="truncate whitespace-nowrap">{"-"}</span>;
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 100,
            render: function Render(value: number, record: IDocuments) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                        {/* <div
                            style={{
                                width: "20px",
                            }}
                        />
                        <button
                            onClick={() => {
                                // onEditItem()
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button> */}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={documents}
                    rowKey="id"
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default DocumentList;
