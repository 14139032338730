import React from 'react';
export const Tops: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.563"
      height="18"
      fill="currentColor"
      {...props}
    >
      <g data-name="Group 12566">
        <path
          data-name="Path 21738"
          d="M9.937 4.5a.375.375 0 01-.375-.375V.375a.375.375 0 01.75 0v3.75a.375.375 0 01-.375.375z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12567">
        <path
          data-name="Path 21739"
          d="M2.625 4.5a.375.375 0 01-.375-.375V.375a.375.375 0 01.75 0v3.75a.375.375 0 01-.375.375z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12568">
        <path
          data-name="Path 21740"
          d="M6.281 5.99a5.59 5.59 0 01-3.9-1.579.375.375 0 11.484-.573 4.853 4.853 0 006.828 0 .375.375 0 11.484.573A5.592 5.592 0 016.281 5.99z"
          fill="currentColor"
        />
      </g>
      <g data-name="Group 12569">
        <path
          data-name="Path 21741"
          d="M12.187 18H.375A.375.375 0 010 17.625V7.874a.375.375 0 01.207-.335 3.271 3.271 0 001.711-2.133l.343-1.372a.375.375 0 11.728.182l-.343 1.372a4.019 4.019 0 01-1.9 2.51v9.153h11.063V8.099a4.017 4.017 0 01-1.9-2.51l-.343-1.372a.375.375 0 11.728-.182l.343 1.372a3.269 3.269 0 001.711 2.133.375.375 0 01.207.335v9.751a.375.375 0 01-.368.374z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
