export const OrdersStatusIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.717 20.3"
    stroke="currentColor"
    {...props}
  >
    <g data-name="order status">
      <g data-name="Group 53">
        <g data-name="Group 52">
          <path
            data-name="Path 82"
            d="M17.488 5.67l-.015-.026a.945.945 0 00-.071-.122L14.62.712a1.128 1.128 0 00-.973-.562H4.071a1.128 1.128 0 00-.975.563L.268 5.619a.587.587 0 00-.057.137.991.991 0 00-.062.345v12.671a1.38 1.38 0 001.379 1.378h14.66a1.38 1.38 0 001.378-1.378V6.05v-.051a.583.583 0 00-.078-.329zM9.465 1.322h4.155l2.188 3.784H9.465zm-5.367 0h4.19v3.784H1.917zm12.3 17.449a.206.206 0 01-.206.206H1.528a.206.206 0 01-.206-.206V6.279h15.073z"
            stroke="currentColor"
            strokeWidth=".3"
          />
        </g>
      </g>
      <g data-name="Group 61">
        <g data-name="Group 60">
          <path
            data-name="Path 86"
            d="M8.877 8.059a4.545 4.545 0 104.546 4.545 4.551 4.551 0 00-4.546-4.545zm0 8.427a3.882 3.882 0 113.882-3.882 3.886 3.886 0 01-3.882 3.882z"
            stroke="currentColor"
            strokeWidth=".6"
          />
        </g>
      </g>
      <g data-name="Group 63">
        <g data-name="Group 62">
          <path
            data-name="Path 87"
            d="M10.425 12.604H8.987v-1.991a.332.332 0 00-.664 0v2.322a.332.332 0 00.332.332h1.77a.332.332 0 000-.664z"
            stroke="currentColor"
            strokeWidth=".6"
          />
        </g>
      </g>
    </g>
  </svg>
);
