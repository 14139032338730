import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import BusinessSetupDetailView from "../views/businessSetup/detail";
import BusinessSetupBranchView from "../views/businessSetup/branch";
import BusinessSetupTeamMemberView from "../views/businessSetup/teamMember";
import BusinessSetupBankCardView from "../views/businessSetup/bankCard";
import BusinessSetupBankAccountView from "../views/businessSetup/bankAccount";
import BusinessSetupServiceTypeView from "../views/businessSetup/serviceType";
import BusinessSetupLogoView from "../views/businessSetup/logo";
import BusinessSetupDocumentsView from "../views/businessSetup/documents";
import BusinessSetupProfileView from "../views/businessSetup/profile";
import BusinessSetupSubscriptionView from "../views/businessSetup/subscription";

const BusinessDetailsRoutes = () => {
    return (
        <Routes>
            <Route
                path={RoutesPath.BusinessSetupDetail}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupDetailView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.BusinessSetupBranch}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupBranchView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.BusinessSetupTeamOrMember}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupTeamMemberView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.BusinessSetupBankCard}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupBankCardView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.BusinessSetupBankAccount}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupBankAccountView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.BusinessSetupServiceType}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupServiceTypeView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.BusinessSetupBusinessLogo}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupLogoView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.BusinessSetupSubscription}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupSubscriptionView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.BusinessSetupBusinessDocuments}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupDocumentsView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.BusinessSetupBusinessProfile}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <BusinessSetupProfileView />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default BusinessDetailsRoutes;
