import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import ProductManagementPolicyView from "../views/productManagement/policy";
import ProductManagementImageView from "../views/productManagement/images";
import ProductManagementVideoView from "../views/productManagement/videos";
import ProductManagementInventoryRoutes from "./productManagementInventory.routes";
import ProductManagementOfferRoutes from "./productManagementOffer.routes";
import ProductManagementInventoryGroupRoutes from "./productManagementInventoryGroup.routes";

const ProductManagementRoutes = () => {
    return (
        <Routes>
            <Route
                path={RoutesPath.ProductManagementPolicy}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementPolicyView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.ProductManagementImage}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementImageView />
                    </AuthorizedRoute>
                }
            />
            <Route
                path={RoutesPath.ProductManagementVideo}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementVideoView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={`${RoutesPath.ProductManagementInventory}/*`}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryRoutes />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={`${RoutesPath.ProductManagementOffer}/*`}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementOfferRoutes />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={`${RoutesPath.ProductManagementInventoryGroup}/*`}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryGroupRoutes />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default ProductManagementRoutes;
