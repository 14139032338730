import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, CaptureDataSectorItem } from "../../types";
import Pagination from "../ui/pagination";

export interface IServiceSectorType extends CaptureDataSectorItem {}

export type IProps = {
    serviceSectorTypeList: IServiceSectorType[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
};

const ServiceSectorTypeList = ({
    serviceSectorTypeList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
}: IProps) => {
    const { t } = useTranslation();

    let columns = [
        {
            // title: t("table:table-item-service-1"),
            title: "Sector/Service Type",
            dataIndex: "sector_title",
            key: "sector_title",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (title: string) => {
                return (
                    <span className="truncate whitespace-nowrap">
                        {title.replaceAll(",", " - ")}
                    </span>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={serviceSectorTypeList}
                    rowKey="id"
                    scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default ServiceSectorTypeList;
