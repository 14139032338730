import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import client from "../client";

export function useProductManagementImageUpload() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.sector_1.uploadImage, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementImageList() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.imageList, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementImageDelete() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.sector_1.imageDelete, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

// Video services
export function useProductManagementVideoList() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.videoList, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementVideoDelete() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.sector_1.videoDelete, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementVideoUpload() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.sector_1.uploadVideo, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

// inventory
export function useProductManagementBrandList() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.brandList, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementList() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.inventoryList, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementDeleteItem() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.inventoryDelete, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementInventoryCreateOrUpdate() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.inventoryCreateOrUpdate, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementInventoryDetails() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.inventoryDetails, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}


export function useProductManagementOfferGridShow() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.offerGridShow, {
        onSuccess: (data) => {
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}


export function useProductManagementOfferCreateOrUpdate() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.offerCreateOrUpdate, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementOfferDetails() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.offerDetails, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementListingGridShow() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.listingGridShow, {
        onSuccess: (data) => {
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}


export function useProductListingPublishByOfferCreateOrUpdate() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.publishByOfferCreateOrUpdate, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductListingUnList() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.productUnList, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}


export function useProductManagementInventoryGroupList() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.inventoryGroupList, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementInventoryGroupCreateOrUpdate() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.inventoryGroupCreateOrUpdate, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementInventoryGroupDetails() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.inventoryGroupDetails, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementInventoryGroupDeleteItem() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.inventoryGroupDelete, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductListingPublishByOfferGroupCreateOrUpdate() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.sector_1.publishByOfferGroupCreateOrUpdate, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}