export const ShopIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.3 15.057" {...props}>
    <g fill="currentColor" stroke="currentColor" strokeWidth=".3">
      <path
        data-name="Path 22617"
        d="M2.258.92a.218.218 0 01.2-.128h13.365a.218.218 0 01.2.127l1.487 3.247a1.125 1.125 0 01-2.249-.066.328.328 0 00-.007-.065.321.321 0 00-.636.065 1.125 1.125 0 01-2.251 0 .321.321 0 00-.642 0 1.125 1.125 0 01-2.251 0 .321.321 0 10-.642 0 1.125 1.125 0 01-2.251 0 .321.321 0 10-.642 0 1.125 1.125 0 01-2.251 0 .321.321 0 10-.642 0 1.125 1.125 0 01-2.249.065L2.261.92zm6.738 13.345V7.694a.6.6 0 00-.6-.6H3.95a.6.6 0 00-.6.6v6.575H2.139V5.858a1.569 1.569 0 012.676.014 1.765 1.765 0 012.893 0 1.765 1.765 0 012.893 0 1.765 1.765 0 012.893 0 1.548 1.548 0 012.652-.017v8.414zm-5.009-.006V7.734H8.35v6.525zM1.492 5.816v8.77a.321.321 0 00.321.321h14.649a.321.321 0 00.321-.321V5.822a1.77 1.77 0 001.366-1.721.321.321 0 00-.029-.134L16.602.652a.862.862 0 00-.782-.5H2.456a.861.861 0 00-.784.506L.178 3.971a.321.321 0 00-.028.132 1.771 1.771 0 001.342 1.715z"
      />
      <path
        data-name="Path 22618"
        d="M15.008 11.895h-4.7V7.734h4.7v4.162zm0-4.8h-4.7a.642.642 0 00-.641.641v4.163a.642.642 0 00.641.641h4.7a.642.642 0 00.641-.641V7.736a.642.642 0 00-.641-.641z"
      />
    </g>
  </svg>
);
