import React from "react";
import cn from "classnames";
import Logo from "../ui/logo";
import { useTranslation } from "react-i18next";
import Label from "../ui/label";
import Button from "../ui/button";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";

export default function Header() {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const menuItems = [
        {
            id: 1,
            title: "Business",
            sumMenu: [
                {
                    id: 11,
                    title: "Add your business",
                },
                {
                    id: 12,
                    title: "Policies",
                },
                {
                    id: 13,
                    title: "Product management",
                },
            ],
        },
        {
            id: 2,
            title: "Analysis",
            sumMenu: [
                {
                    id: 21,
                    title: "Sales analysis",
                },
                {
                    id: 22,
                    title: "Profit margin",
                },
            ],
        },
        {
            id: 3,
            title: "Offer",
            sumMenu: [
                {
                    id: 31,
                    title: "Your offers",
                },
            ],
        },
        {
            id: 4,
            title: "Support",
            sumMenu: [
                {
                    id: 41,
                    title: "Live chat",
                },
                {
                    id: 42,
                    title: "FAQ",
                },
                {
                    id: 43,
                    title: "Support center",
                },
            ],
        },
        {
            id: 5,
            title: "Pricing",
            href: Routes.PubSubscription,
        },
    ];

    return (
        <header
            className={cn("site-header-with-search top-0 z-50 w-full lg:h-22", {
                "sticky lg:fixed": true,
                "sticky border-b border-border-200 shadow-sm": !true,
            })}
        >
            <div>
                <div
                    className={cn(
                        " flex w-full transform-gpu items-center justify-between bg-light transition-transform duration-300 lg:h-22 lg:px-4 xl:px-8"
                    )}
                >
                    <div className="flex w-full shrink-0 grow-0 basis-auto flex-col items-center lg:w-auto lg:flex-row space-x-6">
                        <div
                            className="w-52"
                            onClick={() => {
                                navigate(Routes.landing);
                            }}
                        >
                            <Logo />
                        </div>
                        <div className="align-center flex mt-2 space-x-6 ml-8">
                            {menuItems.map((mnuItem) => {
                                return (
                                    <Link to={mnuItem.href ?? "#"}>
                                        <Label
                                            className="cursor-pointer"
                                            onClick={() => {
                                                // navigate(Routes.dashboard);
                                            }}
                                        >
                                            {mnuItem.title}
                                        </Label>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                    <ul className="hidden shrink-0 items-center space-x-7 rtl:space-x-reverse lg:flex 2xl:space-x-10">
                        <div className="flex items-center space-x-4 rtl:space-x-reverse">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addImageDialog"}
                                loading={false}
                                onClick={() => {
                                    navigate(Routes.register, { replace: true });
                                }}
                            >
                                {"Register"}
                            </Button>

                            <Button
                                size={"small"}
                                form={"addImageDialog"}
                                loading={false}
                                onClick={() => {
                                    navigate(Routes.login, { replace: true });
                                }}
                            >
                                {"Login"}
                            </Button>
                        </div>
                    </ul>
                </div>
                <div
                    className={cn(
                        "w-full border-b border-t border-border-200 bg-light shadow-sm",
                        true ? "hidden lg:block" : "hidden"
                    )}
                ></div>
            </div>
        </header>
    );
}
