// import { useLogout, useResendVerificationEmail, useUser } from "@/framework/user";
// import Button from "@/components/ui/button";
// import Card from "@/components/ui/cards/card";
import { useTranslation } from "react-i18next";
// import Card from "../../components/ui/cards/card";
// import { Logo } from "../../components";
// import Button from "../../components/ui/button";
// import Form from "../../components/ui/forms/form";
import * as yup from "yup";
// import Input from "../../components/ui/forms/input";
import { VerifyEmailInput, VerifyEmailResponse } from "../../types";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../store";
import { useUser, useVerifyEmail } from "../../service/hook/user";
import AuthPageLayout from "../../components/layouts/auth-layout";
import RegistrationForm from "../../components/auth/registration-form";
// import Logo from "@/components/ui/logo";
// import { useToken } from "@/lib/hooks/use-token";
// import { useRouter } from "next/router";
// import Link from "@/components/ui/link";
// import { Routes } from "@/config/routes";

// export { getStaticProps } from "@/framework/general.ssr";

const RegisterView = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("common");

    const {
        accessToken,
        setAccessToken,
        email: registerUserEmail,
        setEmail: setEmailStorage,
        setAuthorized,
        isAuthorized,
    } = authStore((state) => state);

    const {
        mutate: getUser,
        isLoading: userIsLoading,
        serverError: userServerError,
        setServerError: usrSetServerError,
    } = useUser();

    const { mutate: verifyEmailMutate, isLoading: isVerifyLoding } = useVerifyEmail();

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
        setAuthorized(false);
        setAccessToken("");
    };

    function onSubmit({ password_verification_code }: VerifyEmailInput) {
        const request: VerifyEmailInput = { email: registerUserEmail, password_verification_code };

        verifyEmailMutate(request, {
            onSuccess: async (successData: VerifyEmailResponse) => {
                if (isAuthorized && accessToken) {
                    getUser({ email: registerUserEmail });
                }

                if (successData.final_result_code === "000") {
                    navigate("/");
                }
            },
        });
    }

    return (
        <AuthPageLayout>
            <h3 className="mb-6 mt-4 text-center text-base italic text-gray-500">
                {t("admin-register-title")}
            </h3>
            <RegistrationForm />
        </AuthPageLayout>
    );
};
RegisterView.authenticationRequired = true;
export default RegisterView;
