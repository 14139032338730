import ImageCoins from "../../assets/coins.png";
import ImagePhone from "../../assets/phonebg.png";
import ImageProduct from "../../assets/productgb.png";

export default function MarketPlace() {
    const item = (title: string, subtitle: string, image: any) => {
        return (
            <div className="flex bg-stone-100 rounded-lg pl-2 pr-2 w-1/3 items-center justify-between">
                <div>
                    <h1 className="text-lg font-bold text-gray-900 mb-2">{title}</h1>

                    <span className="text-base leading-6 text-gray-800">{subtitle}</span>
                </div>

                <img
                    src={image}
                    alt="landing"
                    style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                        right: -20,
                        bottom: -30,
                    }}
                />
            </div>
        );
    };
    return (
        <div
            style={{
                marginTop: "80px",
                paddingLeft: "200px",
                paddingRight: "200px",
            }}
            className="w-full"
        >
            <div>
                <h1 className="text-2xl text-slate-900 font-bold mb-16">
                    {"Milborak : E-Business Marketplace"}
                </h1>

                <div className="flex justify-between w-full mt-4 space-x-28 w-full">
                    {item("Product", "Products, Services, more", ImageProduct)}
                    {item("AI analysis", "Sales, Customers", ImageCoins)}
                    {item("Unique Webpage", "having unique web address", ImagePhone)}
                </div>
            </div>
        </div>
    );
}
