import { useEffect, useState } from "react";
import { getIcon } from "../../../utils/get-icon";
import * as sidebarIcons from "../../icons/sidebar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SidebarItem = ({
    itemIndex,
    href,
    icon,
    label,
    selected,
    children,
    selectedChildren,
    onClickCallBack,
}: any) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    // const { closeSidebar } = useUI();

    const [extendMenu, setExtendMenu] = useState<boolean>(false);
    const [submenuItems, setSubmenuItems] = useState([]);

    useEffect(() => {
        if (children) {
            let isItem = false;
            let subMenuSelected: string[] = [];
            if (selectedChildren) {
                subMenuSelected = selectedChildren.split("|");

                if (subMenuSelected[0] == itemIndex) {
                    isItem = true;
                }
            }
            setSubmenuItems(
                children.map((mapItem: any, index: number) => {
                    let select = false;
                    if (isItem) {
                        select = parseInt(subMenuSelected[1]) == index;
                    }
                    return { ...mapItem, selected: select };
                })
            );
            if (selected) setExtendMenu(true);
        }
    }, []);

    const menuItemSelectedFontColor = "text-indigo-600";
    const subMenuItemSelectedFontColor = "text-indigo-800";

    const handleNavigate = (path: string) => {
        navigate(`${path}`);
    };

    if (!children) {
        return (
            <div
                // to={href}
                className={`text-start flex w-full items-center text-base text-body-dark focus:text-accent ${
                    selected ? menuItemSelectedFontColor : ""
                }`}
            >
                {getIcon({
                    iconList: sidebarIcons,
                    iconName: icon,
                    className: "w-5 h-5 me-4",
                })}
                <span
                    onClick={() => {
                        // setMenuItemIndexLastSelected(itemIndex);
                        navigate(href, { replace: true });
                        onClickCallBack && onClickCallBack();
                        // closeSidebar && closeSidebar();
                    }}
                >
                    {label}
                </span>
            </div>
        );
    } else {
        return (
            <>
                <div
                    onClick={() => {
                        setExtendMenu(!extendMenu);
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                    className={`text-start flex w-full items-center text-base text-body-dark focus:text-accent ${
                        selected ? menuItemSelectedFontColor : ""
                    }`}
                >
                    {getIcon({
                        iconList: sidebarIcons,
                        iconName: icon,
                        className: "w-5 h-5 me-4",
                    })}
                    <span
                        onClick={() => {
                            navigate(href, { replace: true });
                            onClickCallBack && onClickCallBack();
                            // closeSidebar && closeSidebar();
                        }}
                    >
                        {label}
                    </span>
                </div>
                {extendMenu && (
                    <div
                        style={{
                            paddingLeft: 30,
                        }}
                    >
                        <div className="flex border-b border-dashed border-border-base py-5 sm:py-1" />
                        {submenuItems.map((child: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className={`text-start flex w-full items-center text-base text-body-dark focus:text-accent ${
                                        child.selected ? subMenuItemSelectedFontColor : ""
                                    }`}
                                    style={{
                                        marginTop: 16,
                                        marginBottom: 16,
                                    }}
                                    onClick={() => {
                                        console.log("child?.link => ", child?.link);

                                        // navigate(child.href);
                                        handleNavigate(child?.link ?? "-");
                                        onClickCallBack({
                                            pIndex: itemIndex,
                                            cIndex: index,
                                        });
                                        // closeSidebar && closeSidebar();
                                    }}
                                >
                                    {getIcon({
                                        iconList: sidebarIcons,
                                        iconName: child.icon,
                                        className: "w-5 h-5 me-4",
                                    })}
                                    <span>{t(child.label)}</span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </>
        );
    }
};

export default SidebarItem;
