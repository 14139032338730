import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import ProductListingItemsView from "../views/productListing/items";
import PublishByOfferView from "../views/productListing/publishByOffer";
import ProductListingOfferRoutes from "./productListingOffer.routes";
import ProductListingOfferGroupRoutes from "./productListingOfferGroup.routes";

const ProductListingRoutes = () => {
    return (
        <Routes>
            <Route
                path={RoutesPath.ProductListingItems}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductListingItemsView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductListingPublishByOffer}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <PublishByOfferView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={`/${RoutesPath.ProductListingPublishByOffer}/*`}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductListingOfferRoutes />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={`/${RoutesPath.ProductListingPublishByProductGroup}/*`}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductListingOfferGroupRoutes />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default ProductListingRoutes;
