import IconAdvanced from "../../assets/icon/advanced.png";
import IconDiamond from "../../assets/icon/diamond.png";
import IconPaySetBiometric from "../../assets/icon/pay-set-biometric.png";
import IconPorofits from "../../assets/icon/profits.png";
import IconQualified from "../../assets/icon/qualified.png";
import IconSwapFarming from "../../assets/icon/swap-farming.png";

export default function Benefits() {
    const item = (title: string, subtitle: string, image: any) => {
        return (
            <div className="flex rounded-lg p-2 space-x-8 w-full h-44">
                <img
                    src={image}
                    alt="landing"
                    style={{
                        width: "70px",
                        height: "70px",
                        position: "relative",
                        // right: -50,
                        // bottom: -30,
                    }}
                />

                <div>
                    <h1 className="text-xl text-gray-900 mb-4">{title}</h1>

                    <span className="text-lg leading-6 text-gray-800">{subtitle}</span>
                </div>
            </div>
        );
    };
    return (
        <div
            style={{
                marginTop: "150px",
                paddingLeft: "200px",
                paddingRight: "200px",
            }}
            className="w-full"
        >
            <div
                style={{
                    left: 200,
                    top: 200,
                }}
            >
                <h1 className="text-2xl text-slate-900 font-bold mb-4">{"Other Advantages:"}</h1>

                <div className="flex justify-between w-full mt-4 pt-4 space-y-15">
                    <div className="w-1/2 ">
                        {item(
                            "Low Seller Fees:",
                            "Milborak Offers competitive pricing with a focus on affordability.",
                            IconSwapFarming
                        )}

                        {item(
                            "Integrated Online and In-Person Services:",
                            "Allowing customers to find both online products and in-person services in one platform.",
                            IconDiamond
                        )}

                        {item(
                            "Individualized Online Service:",
                            "Granting businesses their unique online webpages under their own brand and unique address in simplest steps.",
                            IconPaySetBiometric
                        )}

                        {item(
                            "AI Analysis and Business Intelligence: ",
                            "Providing sellers with valuable analysis and reports to empower their business.",
                            IconQualified
                        )}
                    </div>

                    <div className="w-1/2 ">
                        {item(
                            "Diversity and Inclusion: ",
                            "Supporting women entrepreneurs and BAME business holders with exclusive discounts to enhance their business.",
                            IconPorofits
                        )}

                        {item(
                            "Enabling Customers to find the best product and seller: ",
                            "Enabling customers to give score and review for a product and seller will help others to find the high-quality products and seller.",
                            IconAdvanced
                        )}

                        {item(
                            "Financial Monitoring and Assistance: ",
                            "Monitoring financial transactions of sellers and providing sellers with appropriate loans and credits to expand their business.",
                            IconQualified
                        )}

                        {item(
                            "No Warehousing Costs: ",
                            "Operating without a warehouse, reducing the overall cost of running the platform.",
                            IconPaySetBiometric
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
