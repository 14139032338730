import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import * as categoriesIcon from "../icons/category";
import { getIcon } from "../../utils/get-icon";
import { categoryIcons } from "../category/category-icons";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useModalAction } from "../../provider/modal.context";
import {
    BusinessTeamMemberCreateUserResponse,
    BusinessTeamMemberUserShowItem,
    BusinessTeamMemberUserShowResponse,
    BusinessUserCreate,
    Category,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import Checkbox from "../ui/checkbox/checkbox";
import TeamMemberList, { ITeamMember } from "./team-member-list";
import { useBusinessUserCreate, useBusinessUserShowList } from "../../service/hook/business";
import { teamMemberValidationSchema } from "./team-member-validation-schema";
import { userStore } from "../../store";
import { toast } from "react-toastify";

export const updatedIcons = categoryIcons.map((item: any) => {
    item.label = (
        <div className="flex items-center space-s-5">
            <span className="flex h-5 w-5 items-center justify-center">
                {getIcon({
                    iconList: categoriesIcon,
                    iconName: item.value,
                    className: "max-h-full max-w-full",
                })}
            </span>
            <span>{item.label}</span>
        </div>
    );
    return item;
});

type FormValues = {
    email: string;
    mobile?: string;
    isBuyer?: boolean;
    isAdmin?: boolean;
    isTech?: boolean;
    isFinance?: boolean;
};

const defaultValues = {
    email: "",
    mobile: "",
    isBuyer: false,
    isAdmin: false,
    isTech: false,
    isFinance: false,
};

type IProps = {
    initialValues?: Category | undefined;
};
export default function CreateOrUpdateBusinessTeamMemberForm({ initialValues }: IProps) {
    const { t } = useTranslation();

    const { businessItem } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [detailList, setDetailList] = useState<BusinessTeamMemberUserShowItem[]>();

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    // for fill business detail grid list
    const {
        mutate: mutateBusinessUserShow,
        isLoading: isLoadingBusinessUserShow,
        serverError: serverErrorBusinessUserShow,
        setServerError,
    } = useBusinessUserShowList();

    const {
        mutate: mutateBusinessUserCreate,
        isLoading: isLoadingBusinessUserCreate,
        serverError: serverErrorBusinessUserCreate,
        setServerError: setServerErrorBusinessUserCreate,
    } = useBusinessUserCreate();

    useEffect(() => {
        getTeamMemberList();
    }, [businessItem]);

    const getTeamMemberList = () => {
        mutateBusinessUserShow(
            {
                businessdetail_id: businessItem?.business_id ?? 0,
            },
            {
                onSuccess: (successData: BusinessTeamMemberUserShowResponse) => {
                    //set list to state detailList
                    if (successData.final_result_code === "000") {
                        setDetailList(successData.captured_data);
                    }
                },
            }
        );
    };

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver: yupResolver(teamMemberValidationSchema),
    });
    // const { locale } = router;
    // const {
    //     // @ts-ignore
    //     settings: { options },
    // } = useSettingsQuery({
    //     language: locale!,
    // });

    const isAdmin = watch("isAdmin");

    const resetForm = () => {
        reset();
    };

    const checkAnyRollSelect = (input: FormValues) => {
        if (!input.isAdmin && !input.isBuyer && !input.isFinance && !input.isTech) return true;
        return false;
    };

    const onSubmit = async (values: FormValues) => {
        if (checkAnyRollSelect(values)) {
            setError("isFinance", {
                message: t("form:error-no-role-option-selected"),
            });
            return false;
        }

        clearErrors();

        let role: string = "";

        if (values.isBuyer) {
            role = role + (role.length === 0 ? "buyer" : ",buyer");
        }

        if (values.isAdmin) {
            role = role + (role.length === 0 ? "admin" : ",admin");
        } else {
            if (values.isFinance) {
                role = role + (role.length === 0 ? "finance" : ",finance");
            }

            if (values.isTech) {
                role = role + (role.length === 0 ? "tech" : ",tech");
            }
        }

        const input: BusinessUserCreate = {
            email: values.email ?? "",
            business_phone: values?.mobile ?? "",
            first_name: "-",
            last_name: "-",
            role: role,
            group: "seller",
            businessdetail_id: businessItem?.business_id ?? 0,
        };

        mutateBusinessUserCreate(input, {
            onSuccess: (successData: BusinessTeamMemberCreateUserResponse) => {
                if (successData.final_result_code === "000") {
                    resetForm();
                    getTeamMemberList();
                    toast.success(successData.general_result);
                } else {
                    toast.error(successData.general_result);
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    const onDeleteUser = (item: ITeamMember) => {
        openModal("TeamMember_Delete_User", {
            item: { ...item },
            callback: () => {
                getTeamMemberList();
            },
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        // title={t("form:form-title-business-team-member")}
                        details={[
                            t("form:form-description-business-team-member-01"),
                            t("form:form-description-business-team-member-02"),
                        ]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3">
                        <Input
                            label={t("form:input-label-email")}
                            {...register("email")}
                            error={t(errors.email?.message!)}
                            variant="outline"
                            className="mb-5"
                        />
                        <Input
                            label={t("form:input-label-mobile")}
                            {...register("mobile")}
                            error={t(errors.mobile?.message!)}
                            variant="outline"
                            className="mb-5"
                        />

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Checkbox
                                {...register("isBuyer")}
                                label={`${t("form:title-buyer")} ${t(
                                    "form:title-buyer-description"
                                )}`}
                                // disabled={Boolean(buyer)}
                                className="mb-5"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Checkbox
                                {...register("isAdmin")}
                                label={`${t("form:title-admin")} ${t(
                                    "form:title-admin-description"
                                )}`}
                                // disabled={Boolean(admin)}
                                className="mb-5"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Checkbox
                                {...register("isTech")}
                                label={`${t("form:title-tech")} ${t(
                                    "form:title-tech-description"
                                )}`}
                                disabled={isAdmin}
                                className="mb-5"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Checkbox
                                {...register("isFinance")}
                                label={`${t("form:title-finance")} ${t(
                                    "form:title-finance-description"
                                )}`}
                                disabled={isAdmin}
                                className="mb-5"
                                error={errors.isFinance?.message}
                            />
                        </div>
                    </Card>
                </div>

                <div className="mb-4 text-end">
                    <Button loading={isLoadingBusinessUserCreate}>
                        {t("form:item-description-add")}
                    </Button>
                </div>
            </form>
            <TeamMemberList
                onDeleteItem={(item) => {
                    onDeleteUser(item);
                }}
                teamMember={detailList}
                paginatorInfo={null}
                onPagination={handlePagination}
                onOrder={setOrder}
                onSort={setColumn}
            />
        </>
    );
}
